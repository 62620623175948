import { useContext, useEffect, useRef, useState } from 'react';
import { AppContext } from '../../app/provider/app_context';
import { generateUniqueId } from '../../utils/funtions';
import { ProductService } from '../../product/service/product_service';
import bootstrap from 'bootstrap/dist/js/bootstrap.min.js';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import FirebaseStorageService from '../../product/service/firebase_storage_service';
import { Timestamp } from 'firebase/firestore';
import { SoftwareProductService } from '../../software_product/service/software_product_service';

export function convertToEmbedLink(youtubeLink) {
	const videoIdMatch = youtubeLink.match(
		/(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/
	);

	if (videoIdMatch && videoIdMatch.length > 1) {
		const embedLink = `https://www.youtube.com/embed/${videoIdMatch[1]}`;
		return embedLink;
	} else {
		return null;
	}
}

export default function ServiceEditPage() {
	const { showToast } = useContext(AppContext);
	const [loading, setLoading] = useState(false);
	const [editSoftwareProduct, setEditSoftwareProduct] = useState(null);
	const [fetchingProduct, setFetchingProduct] = useState(false);
	const { productId } = useParams();
	const navigate = useNavigate();

	const [uploadProgress, setUploadProgress] = useState(null);

	const [productFeatureImages, setProductFeatureImages] = useState([]);
	const [editedProductFeatureImages, setEditedProductFeatureImages] = useState(false);

	const [productLogo, setProductLogo] = useState(null);
	const [editedProductLogo, setEditedProductLogo] = useState(false);
	const [productName, setProductName] = useState('');
	const [productShortDescription, setProductShortDescription] = useState('');
	const [productPrice, setProductPrice] = useState(0);
	const [productDescription, setProductDescription] = useState(0);
	const [productFeatures, setProductFeatures] = useState(0);
	const [videoLinks, setVideoLinks] = useState([]);

	const fetchProductWithId = async () => {
		if (productId) {
			setFetchingProduct(true);
			const fetchProduct = await SoftwareProductService.getSoftwareProduct(productId);
			prepareEditProduct(fetchProduct);
			setFetchingProduct(false);
		}
	};

	useEffect(() => {
		fetchProductWithId();
	}, []);

	const prepareEditProduct = (softwareProduct) => {
		setEditSoftwareProduct(softwareProduct);
		setProductName(softwareProduct.name);
		setProductShortDescription(softwareProduct.short_description);
		setProductPrice(softwareProduct.price);
		setProductFeatures(softwareProduct.features);
		setProductDescription(softwareProduct.description);
		setVideoLinks(softwareProduct.videos);
	};

	const clearForm = () => {
		setProductName('');
		setProductShortDescription('');
		setProductPrice('');
		setProductFeatures('');
		setProductDescription('');
		setProductLogo(null);
		setProductFeatureImages([]);
		setVideoLinks([]);
	};

	const updateProduct = async () => {
		try {
			setLoading(true);
			let newProductImageObjects = [];
			let newProductLogoImageObject = {};

			if (editedProductLogo) {
				await FirebaseStorageService.delete(editSoftwareProduct.app_logo.refPath);

				newProductLogoImageObject.refPath = `software_products/${editSoftwareProduct.product_id}/app_logo`;
				newProductLogoImageObject.downloadUrl =
					await await FirebaseStorageService.upload(
						newProductLogoImageObject.refPath,
						productLogo,
						productLogo.type,
						(progress) => setUploadProgress(progress)
					);

				setUploadProgress(null);
			} else {
				newProductLogoImageObject = editSoftwareProduct.app_logo
					? editSoftwareProduct.app_logo
					: null;
			}

			if (editedProductFeatureImages) {
				for (const oldImage of editSoftwareProduct.feature_images
					? editSoftwareProduct.feature_images
					: []) {
					await FirebaseStorageService.delete(oldImage.refPath);
				}

				for (const newImage of productFeatureImages) {
					const imageId = generateUniqueId(10);

					const downloadUrl = await FirebaseStorageService.upload(
						`software_products/${productId}/${imageId}`,
						newImage,
						newImage.type,
						(progress) => setUploadProgress(progress)
					);
					newProductImageObjects.push({
						downloadUrl: downloadUrl,
						refPath: `software_products/${productId}/${imageId}`,
					});
				}

				setUploadProgress(null);
			} else {
				newProductImageObjects = editSoftwareProduct.feature_images
					? [...editSoftwareProduct.feature_images]
					: [];
			}

			const softwareProductModel = {
				product_id: productId,
				app_logo: newProductLogoImageObject,
				name: productName,
				short_description: productShortDescription,
				price: productPrice,
				features: productFeatures,
				description: productDescription,
				feature_images: newProductImageObjects,
				videos: videoLinks,
				created_date: Timestamp.now(),
			};
			await SoftwareProductService.updateSoftwareProduct(softwareProductModel);
			showToast('Updated Software Product');

			clearForm();
			setLoading(false);
			navigate(-1);
		} catch (e) {
			if (e.message) {
				showToast(e.message);
			}
			console.log('Product Error is ' + e);
		}
	};

	const submit = async (e) => {
		e.preventDefault();

		if (editSoftwareProduct) {
			await updateProduct();
			return;
		}

		try {
			setLoading(true);
			const newProductId = generateUniqueId(10);
			const appLogoImageObject = {};
			const featureImagesObjects = [];

			appLogoImageObject.refPath = `software_products/${newProductId}/app_logo`;
			appLogoImageObject.downloadUrl = await FirebaseStorageService.upload(
				appLogoImageObject.refPath,
				productLogo,
				productLogo.type,
				(progress) => setUploadProgress(progress)
			);

			for (const featureImage of productFeatureImages) {
				const imageId = generateUniqueId(10);
				const downloadUrl = await FirebaseStorageService.upload(
					`software_products/${newProductId}/${imageId}`,
					featureImage,
					featureImage.type,
					(progress) => setUploadProgress(progress)
				);
				featureImagesObjects.push({
					downloadUrl: downloadUrl,
					refPath: `software_products/${newProductId}/${imageId}`,
				});
			}
			setUploadProgress(null);

			const softwareProductModel = {
				product_id: generateUniqueId(10),
				app_logo: appLogoImageObject,
				name: productName,
				short_description: productShortDescription,
				price: productPrice,
				features: productFeatures,
				description: productDescription,
				feature_images: featureImagesObjects,
				videos: videoLinks,
				created_date: Timestamp.now(),
			};

			await SoftwareProductService.addSoftwareProduct(softwareProductModel);
			showToast('Created Software Product');
			clearForm();
			setLoading(false);
			navigate(-1);
		} catch (e) {
			if (e.message) {
				showToast(e.message);
			}
			console.log('Product Error is ');
			console.log(e);
			console.log('----------------#');
		}
	};
	return (
		<div>
			<div className='min-vh-100 p-3'>
				{fetchingProduct ? (
					<p className=''>Fetching Product ...</p>
				) : (
					<div style={{ maxWidth: 400 }}>
						<p className='h4'>
							{editSoftwareProduct
								? 'Edit Software Product'
								: 'Create Software Product'}
						</p>
						<form onSubmit={submit}>
							<label htmlFor='' className='form-label'>
								Product Image
							</label>

							<br></br>

							{editSoftwareProduct && (
								<div className='d-flex'>
									{editSoftwareProduct.app_logo && (
										<img
											src={
												editSoftwareProduct.app_logo.downloadUrl
											}
											alt=''
											width={200}
											height={200}
											className='border border-gray rounded rounded-4 mb-2 me-2'
										/>
									)}
								</div>
							)}
							{((editSoftwareProduct && editedProductLogo) ||
								!editSoftwareProduct) && (
								<input
									type='file'
									className='form-control mb-2'
									accept='image/*, video/*, audio/*'
									onChange={(e) => {
										setProductLogo(e.target.files[0]);
										setEditedProductLogo(true);
									}}
								/>
							)}
							{!editSoftwareProduct ? (
								<span></span>
							) : editedProductLogo ? (
								<p
									className='text-primary'
									type='button'
									onClick={() => {
										setProductLogo(null);
										setEditedProductLogo(false);
									}}>
									Cancel Edit
								</p>
							) : (
								<p
									className='text-primary'
									type='button'
									onClick={() => {
										setProductLogo(null);
										setEditedProductLogo(true);
									}}>
									Edit Logo
								</p>
							)}

							<label htmlFor='' className='form-label'>
								Feature Images
							</label>

							{editSoftwareProduct && (
								<div className='d-flex'>
									{editSoftwareProduct.feature_images &&
										editSoftwareProduct.feature_images.length > 0 &&
										editSoftwareProduct.feature_images.map(
											(image) => (
												<img
													src={image.downloadUrl}
													alt=''
													width={200}
													height={200}
													className='border border-gray rounded rounded-4 mb-2 me-2'
												/>
											)
										)}
								</div>
							)}
							
							{((editSoftwareProduct && editedProductFeatureImages) ||
								!editSoftwareProduct) && (
								<input
									type='file'
									className='form-control mb-2'
									multiple
									accept='image/*, video/*, audio/*'
									onChange={(e) => {
										setProductFeatureImages(e.target.files);
										setEditedProductFeatureImages(true);
										console.log(e.target.files);
									}}
								/>
							)}
							{!editSoftwareProduct ? (
								<span></span>
							) : editedProductFeatureImages ? (
								<p
									className='text-primary'
									type='button'
									onClick={() => {
										setProductFeatureImages([]);
										setEditedProductFeatureImages(false);
									}}>
									Cancel Edit
								</p>
							) : (
								<p
									className='text-primary'
									type='button'
									onClick={() => {
										setProductFeatureImages([]);
										setEditedProductFeatureImages(true);
									}}>
									Edit Feature Images
								</p>
							)}

							<label className='form-label'>Name</label>
							<input
								type='text'
								className='form-control mb-2'
								value={productName}
								onChange={(e) => setProductName(e.target.value)}
							/>

							<label className='form-label'>Short Description</label>
							<input
								type='text'
								className='form-control mb-2'
								value={productShortDescription}
								onChange={(e) => setProductShortDescription(e.target.value)}
							/>

							<label className='form-label'>Price</label>
							<input
								type='text'
								className='form-control mb-2'
								value={productPrice}
								onChange={(e) => setProductPrice(e.target.value)}
							/>

							<label className='form-label'>Feature List</label>
							<textarea
								type='text'
								className='form-control mb-2'
								rows={5}
								cols={50}
								value={productFeatures}
								onChange={(e) => setProductFeatures(e.target.value)}
							/>

							<label className='form-label'>Description</label>
							<textarea
								type='text'
								className='form-control mb-2'
								rows={5}
								cols={50}
								value={productDescription}
								onChange={(e) => setProductDescription(e.target.value)}
							/>

							<label className='form-label'>Video Links</label>
							<div className='d-flex overflow-x-auto py-2'>
								{videoLinks.map((video) => (
									<div className='d-flex flex-column me-3'>
										<iframe
											className='bg-light rounded rounded-3 mb-2'
											width='170'
											height='100'
											src={convertToEmbedLink(video.link)}
											title='YouTube video player'
											frameborder='0'
											allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
											referrerpolicy='strict-origin-when-cross-origin'
											allowfullscreen></iframe>

										<div className='d-flex justify-content-between'>
											<a
												className='small ms-1 me-2 text-dark'
												href={video.link}
												target='_blank'
												rel='noreferrer'>
												{video.name}
											</a>
											<i
												class='bi bi-x'
												type='button'
												onClick={() =>
													setVideoLinks(
														videoLinks.filter(
															(e) =>
																e.link !==
																video.link
														)
													)
												}></i>
										</div>
									</div>
								))}
							</div>
							<div className='mb-2 mt-3'>
								<AddVideoButton
									onAdded={(video) =>
										setVideoLinks([...videoLinks, video])
									}
								/>
							</div>

							{true && (
								<div
									class='progress'
									role='progressbar'
									aria-label='Animated striped example'
									aria-valuenow='75'
									aria-valuemin='0'
									aria-valuemax='100'>
									<div
										class='progress-bar progress-bar-striped progress-bar-animated'
										style={{ width: `${uploadProgress}%` }}></div>
								</div>
							)}

							<div className='mb-3'></div>
							<div className='d-flex'>
								{loading ? (
									<span className='d-flex align-items-center'>
										<span className='spinner-border me-2'></span>
										<span>Loading..</span>
									</span>
								) : (
									<button className='btn btn-primary px-4'>
										{editSoftwareProduct ? 'Save' : 'Add'}
									</button>
								)}
								{editSoftwareProduct && (
									<DeleteProductButton
										product={editSoftwareProduct}
										onDeleted={() => navigate(-1)}
									/>
								)}
							</div>
						</form>
					</div>
				)}
			</div>
		</div>
	);
}

function AddVideoButton({ onAdded }) {
	const { showToast } = useContext(AppContext);
	const [video, setVideo] = useState({
		name: '',
		link: '',
	});

	const dialog = useRef(null);
	const [modal, setModal] = useState(null);

	const submit = () => {
		if (!video.name) {
			showToast('Video Title is required');
			return;
		}
		if (!video.link) {
			showToast('Video Link is required');
			return;
		}
		onAdded(video);
		setVideo({
			name: '',
			link: '',
		});
		closeDialog();
	};

	const openDialog = async () => {
		var modalDialogInstance = new bootstrap.Modal(dialog.current);
		setModal(modalDialogInstance);
		modalDialogInstance.show();
	};
	const closeDialog = () => {
		// var myModal = document.getElementById('videoAddModal');
		// var modalDialogInstance = new bootstrap.Modal(myModal);
		// modalDialogInstance.hide();
		modal.hide();
	};

	return (
		<>
			<button
				type='button'
				className='btn btn-primary btn-sm'
				onClick={() => openDialog()}>
				Add Video
			</button>

			<div class='modal fade' id='videoAddModal' ref={dialog}>
				<div class='modal-dialog'>
					<div class='modal-content'>
						<div class='modal-header'>
							<h1 class='modal-title fs-5' id='exampleModalLabel'>
								Add Youtube Video
							</h1>
							<button
								type='button'
								class='btn-close'
								data-bs-dismiss='modal'
								aria-label='Close'></button>
						</div>
						<div class='modal-body'>
							<label className='form-label'>Video Title</label>
							<input
								type='text'
								value={video.name}
								className='form-control mb-2'
								onChange={(e) =>
									setVideo({ ...video, name: e.target.value })
								}
							/>
							<label className='form-label'>Video Link</label>
							<input
								type='url'
								value={video.link}
								className='form-control mb-2'
								onChange={(e) =>
									setVideo({ ...video, link: e.target.value })
								}
							/>
						</div>
						<div class='modal-footer'>
							<button
								type='button'
								class='btn btn-secondary'
								data-bs-dismiss='modal'>
								Close
							</button>
							<button type='button' class='btn btn-primary' onClick={submit}>
								Save changes
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

function DeleteProductButton({ product, onDeleted }) {
	const { showToast } = useContext(AppContext);
	const [deleting, setDeleting] = useState(false);

	const dialog = useRef(null);
	const [modal, setModal] = useState(null);

	const submit = async () => {
		try {
			setDeleting(true);
			for (const oldImage of product.images ? product.images : []) {
				await FirebaseStorageService.delete(oldImage.refPath);
			}
			await SoftwareProductService.deleteSoftwareProduct(product.product_id);
			setDeleting(false);
			closeDialog();
			onDeleted();
			showToast('Deleted Software Product');
		} catch (e) {
			console.log('Product Delete Error ' + e);
			if (e.message) {
				showToast(e.message);
			}
		}
	};

	const openDialog = async () => {
		var modalDialogInstance = new bootstrap.Modal(dialog.current);
		setModal(modalDialogInstance);
		modalDialogInstance.show();
	};
	const closeDialog = () => {
		modal.hide();
	};

	return (
		<>
			<button
				type='button'
				className='btn btn-outline-danger ms-3'
				onClick={() => openDialog()}>
				Delete
			</button>

			<div class='modal fade' id='videoAddModal' ref={dialog}>
				<div class='modal-dialog'>
					<div class='modal-content'>
						<div class='modal-header'>
							<h1 class='modal-title fs-5' id='exampleModalLabel'>
								Delete Product
							</h1>
							<button
								type='button'
								class='btn-close'
								data-bs-dismiss='modal'
								aria-label='Close'></button>
						</div>
						<div class='modal-body'>
							<p>Are you sure to delete this product ?</p>
						</div>
						<div class='modal-footer'>
							<button
								type='button'
								class='btn btn-secondary'
								data-bs-dismiss='modal'>
								Close
							</button>
							{deleting ? (
								<span className='spinner-border'></span>
							) : (
								<button
									type='button'
									class='btn btn-danger'
									onClick={submit}>
									Delete
								</button>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
