import { useContext, useEffect, useState } from 'react';
import Footer from '../../app/view/components/Footer';
import NavBar, { NavBarPlacer } from '../../app/view/components/NavBar';
import ProductEditPage from './ProductEditPage';
import './css/DashboardPage.css';
import { Outlet, useLocation, useNavigate, Redirect, Navigate } from 'react-router-dom';
import { AuthContext } from '../../auth/provider/auth_context';

export default function DashboardPage() {
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const [showPanel, setShowPanel] = useState(window.innerWidth > 400);
	const { currentUser } = useContext(AuthContext);

	return (
		<>
			<NavBar hideOnScroll={false} />
			<NavBarPlacer />

			{!currentUser ? (
				<div className='vh-100 vw-100 d-flex justify-content-center align-items-center'>
					<div className='spinner-border mb-5 '></div>
				</div>
			) : !currentUser || currentUser.role !== 'admin' ? (
				<Navigate to='/' />
			) : (
				<div className='d-flex p-0 '>
					<div
						className={`bg-light border-end border-gray panel-sidebar  ${
							showPanel ? 'show-panel' : 'hide-panel'
						}`}>
						<p className='fs-5 mx-3 mt-0 mb-1 '>Admin Panel</p>
						<div
							className={`panel-menu-item ${
								(pathname === '/dashboard' ||
									pathname.includes('/dashboard/user-management')) &&
								'active-item'
							}`}
							onClick={() => navigate('/dashboard')}>
							User Management
						</div>
						<div
							className={`panel-menu-item ${
								pathname.includes('/dashboard/product-management') &&
								'active-item'
							}`}
							onClick={() => navigate('/dashboard/product-management')}>
							Product Management
						</div>
						<div
							className={`panel-menu-item ${
								pathname.includes('/dashboard/service-management') &&
								'active-item'
							}`}
							onClick={() => navigate('/dashboard/service-management')}>
							Service Management
						</div>
					</div>
					<div className='overflow-y-auto w-100' style={{ transition: '0.3s' }}>
						<Outlet />
					</div>
				</div>
			)}

			<Footer />

			<i
				class='bi bi-pencil-square task-management-btn'
				onClick={() => setShowPanel(!showPanel)}></i>
		</>
	);
}
