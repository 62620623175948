import { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useWindowSize, whileScrollingDown } from '../../../utils/funtions';
import { AuthContext } from '../../../auth/provider/auth_context';
import FirebaseAuthService from '../../../auth/service/auth_service';
import appLogo from '../../../assets/images/app_logo.png';

export function NavBarPlacer() {
	return <div style={{ height: '55px' }}></div>;
}

export default function NavBar({ hideOnScroll, useBlurBackground }) {
	const { pathname } = useLocation();
	const [scrollingDown, setScrollingDown] = useState(false);
	const { currentUser } = useContext(AuthContext);

	const navigate = useNavigate();

	const navBarStyle = {
		transition: '0.5s transform',
		transform: !scrollingDown || !hideOnScroll ? 'translate(0,0)' : 'translate(0,-60px)',
		maxWidth: '100vw',
	};

	useEffect(function () {
		whileScrollingDown((scrollDown) => setScrollingDown(scrollDown));
	}, []);

	return (
		<nav
			class={`navbar navbar-expand-lg fixed-top  ${
				!hideOnScroll && 'border-bottom border-gray'
			} ${useBlurBackground ? 'nav-bar-blur text-white' : 'bg-light'}`}
			style={navBarStyle}>
			<div class='container-fluid'>
				<Link class={`navbar-brand ${useBlurBackground && 'text-white'}`} to='/'>
					Kanbawza App Studio
				</Link>
				<button
					class={`navbar-toggler ${useBlurBackground && 'navbar-dark'}`}
					type='button'
					data-bs-toggle='collapse'
					data-bs-target='#navbarNav'
					aria-controls='navbarNav'
					aria-expanded='false'
					aria-label='Toggle navigation'>
					<span class='navbar-toggler-icon '></span>
				</button>
				<div class='collapse navbar-collapse' id='navbarNav'>
					<ul class='navbar-nav ms-auto me-1'>
						<li class='nav-item'>
							<Link
								className={`nav-link ${useBlurBackground && 'text-white'} ${
									pathname === '/products' && 'active'
								}`}
								to='/products'>
								Products
							</Link>
						</li>
						<li class='nav-item'>
							<Link
								className={`nav-link ${useBlurBackground && 'text-white'} ${
									pathname === '/services' && 'active'
								}`}
								to='/services'>
								Softwares
							</Link>
						</li>
						<li class='nav-item'>
							<Link
								className={`nav-link ${useBlurBackground && 'text-white'} ${
									pathname === '/about' && 'active'
								}`}
								to='/about'>
								About Us
							</Link>
						</li>

						{currentUser ? (
							window.innerWidth < 1000 ? (
								<>
									{currentUser.role === 'admin' && (
										<li class='nav-item'>
											<Link
												to=''
												className={`nav-link ${
													useBlurBackground && 'text-white'
												} `}
												onClick={() => navigate('/dashboard')}>
												Admin Panel
											</Link>
										</li>
									)}
									<li class='nav-item'>
										<Link
											to=''
											className={`nav-link ${
												useBlurBackground && 'text-white'
											} `}
											onClick={() => navigate('/profile')}>
											Profile
										</Link>
									</li>
									<li class='nav-item'>
										<Link
											to=''
											className={`nav-link ${
												useBlurBackground && 'text-white'
											} `}
											onClick={async () => {
												await FirebaseAuthService.logOut();
												navigate('/login');
											}}>
											Log Out
										</Link>
									</li>
								</>
							) : (
								<div class='dropstart'>
									<img
										src={appLogo}
										alt='Kanbawza'
										width={40}
										height={40}
										className='border border-gray rounded rounded-circle dropdown-toggle'
										role='button'
										data-bs-toggle='dropdown'
										aria-expanded='false'
									/>

									<ul class='dropdown-menu'>
										{currentUser.role === 'admin' && (
											<li>
												<Link
													to=''
													className='dropdown-item'
													onClick={() =>
														navigate('/dashboard')
													}>
													Admin Panel
												</Link>
											</li>
										)}
										<li>
											<Link
												to=''
												className='dropdown-item'
												onClick={() => navigate('/profile')}>
												Profile
											</Link>
										</li>
										<li>
											<Link
												to=''
												className='dropdown-item'
												onClick={async () => {
													await FirebaseAuthService.logOut();
													navigate('/login');
												}}>
												Log Out
											</Link>
										</li>
									</ul>
								</div>
							)
						) : (
							<li class='nav-item '>
								<Link
									to=''
									className={`nav-link ${
										useBlurBackground && 'text-white'
									} `}
									onClick={async () => {
										await FirebaseAuthService.logOut();
										navigate('/login');
									}}>
									Log In
								</Link>
							</li>
						)}
					</ul>
				</div>
			</div>
		</nav>
	);
}
