import { useContext, useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { AppContext } from '../../app/provider/app_context';
import { ProductService } from '../../product/service/product_service';
import { remove } from 'firebase/database';
import { Timestamp } from 'firebase/firestore';
import { QueryLimitConstraint } from 'firebase/firestore/lite';
import appLogo from '../../assets/images/app_logo.png';

export default function ProductManagement() {
	const { showToast } = useContext(AppContext);
	const navigate = useNavigate();

	const [products, setProducts] = useState([]);

	useEffect(() => {
		fetchProducts();
	}, []);

	// const [lastDoc, setLastDoc] = useState(null);
	// const [pages, setPages] = useState([]);
	// 	const nextPaginate = async () => {
	// 		const data = await ProductService.paginateFetch(lastDoc, 5);
	// 		if (data) {
	// 			setProducts(data);
	// 			const lastProduct = data[data.length - 1];
	// 			setLastDoc(lastProduct);
	//
	// 			setPages([...pages, lastProduct]);
	// 			console.log(`Last Doc - ${lastProduct.product_id}`);
	// 		}
	// 	};
	//
	// 	const previousPaginate = async () => {
	// 		const prevLastProduct = pages[pages.length - 2];
	// 		const lastProduct = pages[pages.length - 1];
	// 		let clearLastPage = pages.filter((p) => p.product_id !== prevLastProduct.product_id);
	// 		clearLastPage = clearLastPage.filter((p) => p.product_id !== lastProduct.product_id);
	//
	//
	// 		setPages(clearLastPage);
	// 		const previousPageId =
	// 			clearLastPage.length === 0 ? null : clearLastPage[clearLastPage.length - 1];
	//
	// 		setLastDoc(previousPageId);
	// 		console.log(clearLastPage.map((p) => p.product_id));
	//
	// 		const data = await ProductService.paginateFetch(previousPageId, 5);
	// 		if (data) {
	// 			setProducts(data);
	// 			const lastProduct = data[data.length - 1];
	// 			setLastDoc(lastProduct);
	// 			setPages([...pages, lastProduct]);
	// 			console.log(`Last Doc - ${lastProduct.product_id}`);
	// 		}
	// 	};

	const fetchProducts = async () => {
		const data = await ProductService.getProducts();
		// const clone = [...data, ...data, ...data, ...data, ...data, ...data, ...data, ...data];
		setProducts(data);

		window.scroll(0, 0);
	};

	return (
		<div className='min-vh-100 p-lg-4 p-3 ' style={{ minWidth: 400, width: '100%' }}>
			<p className='h4'>Product Management</p>
			<button
				className='btn btn-primary btn-sm mb-3'
				onClick={() => navigate('/dashboard/product-management/add')}>
				Add Product
			</button>

			<table className='table table-striped table-hover table-responsive border'>
				<thead>
					<tr>
						<th className='ps-3'>No</th>
						<th>Image</th>
						<th>Name</th>
						<th>Price</th>
						<th>Stock</th>
						<th>Option</th>
					</tr>
				</thead>
				<tbody>
					{products.map((product, index) => (
						<tr>
							<td className='ps-3'>{index + 1}</td>
							<td>
								<img
									src={product.images && product.images.length > 0
										? product.images[0].downloadUrl
										: appLogo}
									alt=''
									width={30}
									height={30}
									className='rounded-circle border border-gray'
								/>
							</td>
							<td>{product.name}</td>
							<td>{product.price}</td>
							<td>{product.stock}</td>
							<td>
								<Link
									className='my-0 me-3 '
									to={`/dashboard/product-management/edit/${product.product_id}`}>
									Edit
								</Link>
								<Link
									className='my-0 '
									to={`/products/${product.product_id}`}>
									View
								</Link>
							</td>
						</tr>
					))}
					{/* <tr>
						<td colSpan={5}></td>
						<td>
							<button
								className='btn btn-outline-primary btn-sm me-3 rounded-pill px-3'
								onClick={() => previousPaginate()}>
								Previous
							</button>
							<button
								className='btn btn-outline-primary btn-sm rounded-pill px-3'
								onClick={() => nextPaginate()}>
								Next
							</button>
						</td>
					</tr> */}
				</tbody>
			</table>
		</div>
	);
}
