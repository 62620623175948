import { fireStorage } from '../../firebase';
import { getStorage, ref, deleteObject } from 'firebase/storage';

export default class FirebaseStorageService {
	static async upload(filename, file, contentType, onProgress, onDone) {
		const uploadTask = fireStorage.ref(filename).put(file, { contentType: contentType });
		uploadTask.on('state_changed', (snapshot) => {
			const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
			onProgress(progress);
		});
		await uploadTask;
		const url = await fireStorage.ref(filename).getDownloadURL();
		return url;
	}

	static async delete(refPath) {
		if (!refPath) return;
		const storage = getStorage();
		const fileRef = ref(storage, refPath);

		try {
			await deleteObject(fileRef);
			console.log('File deleted successfully');
		} catch (error) {
			console.error('Error deleting file:', error);
		}
	}
}
