import { useEffect, useRef, useState } from "react";

export function whileScrollingDown(callback) {
    let lastScrollTop = 0;

    const handleScroll = () => {
        const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;
        callback(currentScrollTop > lastScrollTop);
        lastScrollTop = currentScrollTop <= 0 ? 0 : currentScrollTop;
    };

    window.addEventListener('scroll', handleScroll);
}



export function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
        screenWidth: window.innerWidth,
        screenHeight: window.innerHeight,
    });

    const handleResize = () => {
        setWindowSize({
            screenWidth: window.innerWidth,
            screenHeight: window.innerHeight,
        });

    };


    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return windowSize;
};



export function delaySecond(seconds) {
    return new Promise((resolve, reject) => setTimeout(resolve, seconds * 1000))
}


export function generateUniqueId(length) {
    // Calculate half of the desired length
    const halfLength = Math.ceil(length / 2);

    // Generate a random number and convert it to a hexadecimal string with half of the desired length
    const randomPart = Math.random().toString(16).substr(2, halfLength);

    // Get the current timestamp and convert it to a hexadecimal string with the other half of the desired length
    const timestampPart = (new Date().getTime()).toString(16).substr(0, length - halfLength);

    // Concatenate the random and timestamp parts to create the unique ID
    const uniqueId = randomPart + timestampPart;

    return uniqueId;
}

export function useInViewport() {
    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsVisible(entry.isIntersecting);
                console.log("Visible - " + isVisible);
            }, {
                root: null,
                rootMargin: '0px',
                threshold: 0.5, // Adjust threshold as needed
            }
        );

        if (ref.current) {
            observer.observe(ref.current);
        }

        // Cleanup observer
        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, []);

    return [isVisible, ref];

}