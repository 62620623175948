import { useContext, useState } from 'react';
import { Timestamp } from 'firebase/firestore';
import FirebaseAuthService from '../service/auth_service';
import { Link, useNavigate } from 'react-router-dom';
import { AppContext } from '../../app/provider/app_context';
import { AuthContext } from '../provider/auth_context';

export default function SignUpPage() {
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [loading, setLoading] = useState(false);
	const [passwordVisible, setPasswordVisible] = useState(false);
	const { showToast } = useContext(AppContext);
	const navigate = useNavigate();
	const { authorize } = useContext(AuthContext);

	const submit = async (event) => {
		event.preventDefault();

		if (!name) {
			showToast('Name is required');
			return;
		}
		if (!email) {
			showToast('Email is required');
			return;
		}
		if (!password) {
			showToast('Password is required');
			return;
		}

		const newUser = {
			name: name,
			email: email,
			password: password,
			created_date: Timestamp.now(),
			role: 'user',
		};
		setLoading(true);
		console.log(newUser);
		const user = await FirebaseAuthService.signUp(newUser, (error) =>
			showToast(error.message)
		);

		if (user) {
			await FirebaseAuthService.saveUserCredential(user);
			await authorize();
			showToast('Created User');
			navigate('/');
		}

		setLoading(false);
	};

	return (
		<div className='vw-100 vh-100 bg-light row justify-content-center align-items-center m-0 px-3 '>
			<div
				className='col-sm-5 bg-white rounded rounded-4 shadow-sm border border-gray'
				style={{ maxWidth: '400px' }}>
				<p className='h4 mt-4 mb-1'>Kanbawza App Studio</p>
				<p className=''>Create Account</p>
				<form onSubmit={submit}>
					<label htmlFor=''>Name</label>
					<input
						type='text'
						name='name'
						className='form-control mb-2'
						value={name}
						onChange={(e) => setName(e.target.value)}
					/>
					<label htmlFor=''>Email</label>
					<input
						type='email'
						name='email'
						className='form-control mb-2'
						value={email}
						onChange={(e) => setEmail(e.target.value)}
					/>
					<label htmlFor=''>Password</label>
					<div className='position-relative'>
						<input
							type={passwordVisible ? 'text' : 'password'}
							name='password'
							className='form-control mb-4'
							value={password}
							onChange={(e) => setPassword(e.target.value)}
						/>
						<div
							className='position-absolute'
							style={{
								top: '50%',
								right: '20px',
								transform: 'translateY(-50%)',
							}}>
							<i
								className={`bi ${
									passwordVisible ? 'bi-eye-fill' : 'bi-eye-slash-fill'
								} fs-5`}
								onClick={() => setPasswordVisible(!passwordVisible)}></i>
						</div>
					</div>

					{loading ? (
						<button className='btn btn-primary disabled rounded-3 w-100 mb-5'>
							<span className='me-3'>Loading</span>
							<span
								className='spinner-border '
								style={{ width: 20, height: 20, borderWidth: 2 }}></span>
						</button>
					) : (
						<button className='btn btn-primary rounded-3 w-100 mb-4'>
							Create Account
						</button>
					)}
				</form>
				<div className='mb-3 text-center'>
					<Link to='/login' className='text-secondary' replace>
						Log In
					</Link>
				</div>
			</div>
		</div>
	);
}
