import { useContext, useEffect, useRef, useState, useTransition } from 'react';
import Footer from '../../app/view/components/Footer';
import NavBar, { NavBarPlacer } from '../../app/view/components/NavBar';
import { AppContext } from '../../app/provider/app_context';
import { useNavigate } from 'react-router-dom';
import appLogo from '../../assets/images/app_logo.png';
import { useWindowSize } from '../../utils/funtions';
import { SoftwareProductService } from '../service/software_product_service';

export default function SoftwareProductPage() {
	const { showToast } = useContext(AppContext);
	const navigate = useNavigate();
	const { screenWidth } = useWindowSize();

	let isMobile = screenWidth < 576;

	const [softwareProducts, setSoftwareProducts] = useState([]);
	const [fetchingProduct, setFetchingProduct] = useState(false);

	useEffect(() => {
		fetchProducts();
	}, []);

	const fetchProducts = async () => {
		setFetchingProduct(true);
		const data = await SoftwareProductService.getSoftwarePackages();
		// const clone = [...data, ...data, ...data, ...data, ...data, ...data, ...data, ...data];
		setSoftwareProducts(data);
		setFetchingProduct(false)
		console.log(data);
		window.scroll(0, 0);
	};

	return (
		<div>
			<NavBar hideOnScroll={false} />
			<NavBarPlacer />
			{fetchingProduct ? (
			<div className='vh-100 vw-100 d-flex justify-content-center align-items-center'>
				<div className='spinner-border mb-5'></div>
			</div>
			) :
			<div className='min-vh-100 bg-light'>
				<div className='row justify-content-center py-3 mx-0 '>
					{softwareProducts.map((softwareProduct) => (
						<div
							className='col-sm-5 card rounded rounded-4 printer-card'
							style={{
								minWidth: isMobile ? 100 : 160,
								maxWidth: isMobile ? '90vw' : 300,
								margin: '10px',
							}}
							onClick={() =>
								navigate(`/services/${softwareProduct.product_id}`)
							}>
							<img
								src={
									softwareProduct.app_logo
										? softwareProduct.app_logo.downloadUrl
										: appLogo
								}
								alt={softwareProduct.name}
								className='card-img-top rounded-top-4'
								width={200}
								height={200}
							/>
							<div className='card-body p-2'>
								<strong className='card-title'>
									{softwareProduct.name}
								</strong>
								<p className='my-0'>{softwareProduct.short_description}</p>
								<p className='my-0'>Price - {softwareProduct.price}</p>
							</div>
						</div>
					))}
				</div>
			</div>
			}
			<Footer />
		</div>
	);
}
