import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import FirebaseAuthService from '../../auth/service/auth_service';

export default function UserManagement() {
	const navigate = useNavigate();

	const [users, setUsers] = useState([]);

	useEffect(() => {
		fetchUsers();
	}, []);

	const fetchUsers = async () => {
		const data = await FirebaseAuthService.getAllUsers();
		// const clone = [...data, ...data, ...data, ...data, ...data, ...data, ...data, ...data];
		setUsers(data);
		window.scroll(0, 0);
	};

	return (
		<div className='min-vh-100 p-lg-4 p-3'>
			<p className='h4'>User Management</p>
			<button
				className='btn btn-primary btn-sm mb-3'
				onClick={() => navigate('/dashboard/user-management/add')}>
				Add User
			</button>
			<table className='table table-striped table-hover table-responsive border'>
				<thead>
					<tr>
						<th className='ps-3'>No</th>
						<th>Name</th>
						<th>Email</th>
						<th>Role</th>
						<th>Option</th>
					</tr>
				</thead>
				<tbody>
					{users.map((user, index) => (
						<tr>
							<td className='ps-3'>{index + 1}</td>
							<td>{user.name}</td>
							<td>{user.email}</td>
							<td>{user.role}</td>
							<td>
								<Link
									className='my-0 me-3 '
									to={`/dashboard/user-management/edit/${user.id}`}>
									Edit
								</Link>
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
}
