import '../css/footer.css';
import appLogo from '../../../assets/images/app_logo.png';
import { Link } from 'react-router-dom';

export default function Footer() {
	return (
		<footer className='footer row m-0 '>
			<div
				className='col-sm-3 d-flex flex-column align-items-start align-items-sm-center'
				style={{ maxWidth: 300 }}>
				<img
					src={appLogo}
					alt='Kanbawza App Studio'
					width={150}
					height={150}
					className='border border-gray rounded rounded-4 shadow-sm mb-2'
				/>
				<p className='fs-5 text-white shadow-sm footer-appname my-0'>
					Kanbawza App Studio
				</p>
				<p className='fs-6 my-0 text-white text-light'>Make It Easy and Fast</p>
			</div>
			<div className='col-sm-2 d-flex flex-column p-3 text-white'>
				<p className='my-0 mb-1' style={{ fontSize: '1.3em' }}>
					<small>Visit Sites</small>
				</p>
				<Link to='/products' className='link-text'>
					See Products
				</Link>
				<Link to='/services' className='link-text '>
					See Softwares
				</Link>
				<Link to='/about' className='link-text '>
					Contact Us
				</Link>
			</div>
			<div className='col-sm-2 d-flex flex-column p-3 text-white'>
				<p className='my-0 mb-1' style={{ fontSize: '1.3em' }}>
					<small>Contact Us</small>
				</p>
				<p className='my-0'>Lashio City, Shan State</p>
				<a
					className='my-0 link-text'
					href='tel:+959445106229'
					target='_blank'
					rel='noreferrer'>
					+959445106229
				</a>
				<a
					className='my-0 link-text mb-4'
					href='tel:+959755513130'
					target='_blank'
					rel='noreferrer'>
					+959755513130
				</a>

				<p className='my-0 mb-1' style={{ fontSize: '1.3em' }}>
					<small>Location</small>
				</p>
				<a
					className='my-0 link-text'
					href='https://www.google.com/maps?q=22.206,97.612'
					target='_blank'
					rel='noreferrer'>
					See on map
				</a>
			</div>
			<div className='col-sm-2 d-flex flex-column p-3 text-white'>
				<p className='my-0 mb-1' style={{ fontSize: '1.3em' }}>
					<small>Community</small>
				</p>
				<a
					className='my-0 link-text'
					href='https://www.facebook.com/profile.php?id=100092730696504'
					target='_blank'
					rel='noreferrer'>
					Facebook
				</a>
				<a
					className='my-0 link-text mb-4'
					href='https://www.tiktok.com'
					target='_blank'
					rel='noreferrer'>
					Tiktok
				</a>
			</div>
		</footer>
	);
}
