import { useContext, useEffect, useRef, useState, useTransition } from 'react';
import Footer from '../../app/view/components/Footer';
import NavBar, { NavBarPlacer } from '../../app/view/components/NavBar';
import { AppContext } from '../../app/provider/app_context';
import { ProductService } from '../service/product_service';
import './css/ProductPage.css';
import { useNavigate } from 'react-router-dom';
import appLogo from '../../assets/images/app_logo.png';
import { useWindowSize } from '../../utils/funtions';
import FacebookShare from '../../app/view/components/FacebookShare';

export default function ProductPage() {
	const { showToast } = useContext(AppContext);
	const navigate = useNavigate();
	const { screenWidth } = useWindowSize();
	let isMobile = screenWidth < 576;

	const [fetchingProduct, setFetchingProduct] = useState(false);

	const [products, setProducts] = useState([]);

	useEffect(() => {
		fetchProducts();
	}, []);

	const fetchProducts = async () => {
		window.scroll(0, 0);
		setFetchingProduct(true);
		const data = await ProductService.getProducts();
		// const clone = [...data, ...data, ...data, ...data, ...data, ...data, ...data, ...data];
		const sortedData = data.sort((a, b) => Number(a.price) > Number(b.price));
		setProducts(sortedData);
		setFetchingProduct(false);
	};

	return (
		<div>
			
			<NavBar hideOnScroll={false} />
			<NavBarPlacer />
			{fetchingProduct ? (
				<div className='vh-100 vw-100 d-flex justify-content-center align-items-center'>
					<div className='spinner-border mb-5 '></div>
				</div>
			) : (
				<div className='min-vh-100 bg-light'>
					<div className='row justify-content-center py-3 mx-0 ' style={{}}>
						{products.map((product) => (
							<div
								className='col-1 card rounded rounded-4 printer-card p-0'
								style={{
									minWidth: isMobile ? 100 : 160,
									maxWidth: 300,
									width: isMobile ? '45vw' : '',
									margin: isMobile ? '5px' : '10px 10px',
								}}
								onClick={() => navigate(`/products/${product.product_id}`)}>
								<img
									src={
										product.images && product.images.length > 0
											? product.images[0].downloadUrl
											: appLogo
									}
									alt={product.name}
									className='card-img-top rounded-top-4'
									width={200}
									height={150}
								/>
								<div className='card-body p-3'>
									<strong className='card-title'>{product.name}</strong>

									<p className='my-0'>Price - {product.price} Ks</p>
									<p className='my-0'>
										{product.stock <= 0 ? (
											<small className='text-secondary'>No Stock</small>
										) : (
											<small className='text-success'>
												Available Now
											</small>
										)}
									</p>
								</div>
							</div>
						))}
					</div>
				</div>
			)}
			<Footer />
		</div>
	);
}
