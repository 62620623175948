import { Timestamp } from 'firebase/firestore';
import { firestore } from '../../firebase';

export class ProductService {
	static async getProducts() {
		const querySnap = await firestore
			.collection('products')
			.orderBy('created_date', 'desc')
			.get();
		const data = querySnap.docs.map((doc) => doc.data());
		return data;
	}

	static async paginateFetch(lastDoc, limit) {
		let query = firestore.collection('products').orderBy('created_date', 'desc').limit(limit);

		if (lastDoc) {
			query = query.startAfter(lastDoc.created_date).limit(limit);
			console.log(`I gonnna paginate with ${lastDoc.product_id} with ${limit} count`);
		}
		const querySnap = await query.get();
		const data = querySnap.docs.map((doc) => doc.data());
		return data;
	}

	static async getProduct(productId) {
		const docSnap = await firestore.collection('products').doc(productId).get();
		if (docSnap.exists) {
			return docSnap.data();
		}
		return null;
	}

	static async get5Products(productId) {
		const querySnap = await firestore
			.collection('products')
			// .orderBy('created_date', 'desc')
			.limit(5)
			.get();
		const data = querySnap.docs.map((doc) => doc.data());
		return data;
	}

	static async addProduct(product) {
		if (!product.product_id) {
			console.log('Product ID is required');
			return;
		}
		await firestore.collection('products').doc(product.product_id).set(product);
	}

	static async updateProduct(product) {
		if (!product.product_id) {
			console.log('Product ID is required');
			return;
		}
		await firestore.collection('products').doc(product.product_id).update(product);
	}

	static async deleteProduct(productId) {
		if (!productId) {
			console.log('Product ID is required');
			return;
		}
		await firestore.collection('products').doc(productId).delete();
	}
}
