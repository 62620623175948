import { useContext, useEffect, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import FirebaseAuthService from '../../auth/service/auth_service';
import { AppContext } from '../../app/provider/app_context';
import { Timestamp } from 'firebase/firestore';
import bootstrap from 'bootstrap/dist/js/bootstrap.min.js';

export default function UserEditPage() {
	const { showToast } = useContext(AppContext);
	const { userId } = useParams();
	const [fetchingUser, setFetchingUser] = useState(false);
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	const [editUser, setEditUser] = useState(null);
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [role, setRole] = useState('');

	const fetchUserWithId = async () => {
		if (userId) {
			setFetchingUser(true);
			const fetchUser = await FirebaseAuthService.getUserModel(userId);
			prepareEditUser(fetchUser);
			setFetchingUser(false);
		}
	};

	useEffect(() => {
		fetchUserWithId();
	}, []);

	const prepareEditUser = (user) => {
		setName(user.name);
		setEmail(user.email);
		setRole(user.role);
		setPassword('Password is not allowed to edit');
		setEditUser(user);
	};

	const clearForm = () => {
		setName('');
		setEmail('');
		setRole('');
	};

	const updateUser = async () => {
		try {
			setLoading(true);
			const userModel = {
				id: editUser.id,
				name: name,
				email: email,
				role: role,
			};
			await FirebaseAuthService.updateUserModel(userModel);
			showToast('Updated User');
			clearForm();
			setLoading(false);
			navigate(-1);
		} catch (e) {
			if (e.message) {
				showToast(e.message);
			}
			console.log('Product Error is ' + e);
		}
	};

	const submit = async (e) => {
		e.preventDefault();

		if (editUser) {
			await updateUser();
			return;
		}
		if (!name) {
			showToast('Name is required');
			return;
		}
		if (!email) {
			showToast('Email is required');
			return;
		}
		if (!role) {
			showToast('Role is required');
			return;
		}

		if (!password) {
			showToast('Password is required');
			return;
		}

		try {
			setLoading(true);
			const newUser = {
				name: name,
				email: email,
				password: password,
				created_date: Timestamp.now(),
				role: role,
			};
			await FirebaseAuthService.signUp(newUser, (error) => showToast(error.message));
			showToast('User is created');
			clearForm();
			setLoading(false);
			navigate(-1);
		} catch (e) {
			if (e.message) {
				showToast(e.message);
			}
			console.log(`User Create Error - ${e}`);
		}
	};

	return (
		<div>
			<div className='min-vh-100 p-3'>
				{fetchingUser ? (
					<p className=''>Fetching User Data ...</p>
				) : (
					<div style={{ maxWidth: 400 }}>
						<p className='h4'>{editUser ? 'Edit User' : 'Create User'}</p>
						<form onSubmit={submit}>
							<label className='form-label'>Name</label>
							<input
								type='text'
								className='form-control mb-2'
								value={name}
								onChange={(e) => setName(e.target.value)}
							/>

							<label className='form-label'>Email</label>
							<input
								type='text'
								className='form-control mb-2'
								value={email}
								onChange={(e) => setEmail(e.target.value)}
							/>

							<label className='form-label'>Password</label>
							<input
								type='text'
								className='form-control mb-2'
								value={password}
								disabled={editUser}
								onChange={(e) => setPassword(e.target.value)}
							/>

							<label className='form-label'>Role</label>
							<select
								className='form-select'
								onChange={(e) => setRole(e.target.value)}
								value={role}>
								<option>Choose Role</option>
								<option value='user'>User</option>
								<option value='admin'>Admin</option>
							</select>

							<div className='mb-3'></div>
							<div className='d-flex'>
								{loading ? (
									<span className='d-flex align-items-center'>
										<span className='spinner-border me-2'></span>
										<span>Loading..</span>
									</span>
								) : (
									<button className='btn btn-primary px-4'>
										{editUser ? 'Save' : 'Add'}
									</button>
								)}
                               
								{editUser && (
									<DeleteUserButton
										userId={editUser.id}
										onDeleted={() => navigate(-1)}
									/>
								)}
							</div>
						</form>
					</div>
				)}
			</div>
		</div>
	);
}

export function DeleteUserButton({ userId, onDeleted }) {
	const { showToast } = useContext(AppContext);
	const [deleting, setDeleting] = useState(false);

	const dialog = useRef(null);
	const [modal, setModal] = useState(null);

	const submit = async () => {
		try {
			setDeleting(true);
			await FirebaseAuthService.deleteUserModel(userId);
			setDeleting(false);
			closeDialog();
			onDeleted();
			showToast('Deleted User');
		} catch (e) {
			console.log('User Delete Error ' + e);
			if (e.message) {
				showToast(e.message);
			}
		}
	};

	const openDialog = async () => {
		var modalDialogInstance = new bootstrap.Modal(dialog.current);
		setModal(modalDialogInstance);
		modalDialogInstance.show();
	};
	const closeDialog = () => {
		modal.hide();
	};

	return (
		<>
			<button
				type='button'
				className='btn btn-outline-danger ms-3'
				onClick={() => openDialog()}>
				Delete 
			</button>

			<div class='modal fade' id='videoAddModal' ref={dialog}>
				<div class='modal-dialog'>
					<div class='modal-content'>
						<div class='modal-header'>
							<h1 class='modal-title fs-5' id='exampleModalLabel'>
								Delete User
							</h1>
							<button
								type='button'
								class='btn-close'
								data-bs-dismiss='modal'
								aria-label='Close'></button>
						</div>
						<div class='modal-body'>
							<p>Are you sure to delete this user ?</p>
						</div>
						<div class='modal-footer'>
							<button
								type='button'
								class='btn btn-secondary'
								data-bs-dismiss='modal'>
								Close
							</button>
							{deleting ? (
								<span className='spinner-border'></span>
							) : (
								<button
									type='button'
									class='btn btn-danger'
									onClick={submit}>
									Delete
								</button>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
