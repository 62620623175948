import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../auth/provider/auth_context';

export default function NotFoundPage() {
	const navigate = useNavigate();
	
	return (
		<div
			className='vh-100 vw-100 d-flex flex-column justify-content-center align-items-center text-dark bg-light text-decoration-none p-3'
			id='homepage'>
				<img src="https://cdn-icons-png.flaticon.com/512/11119/11119924.png" alt="Error" height={100} width={100} className='mb-4'/>
			<p className='h3'>404 Not Found</p>
			<p className='text-secondary text-center'>Something went wrong ! If you need some help, contact us right away.</p>
			<button className='btn btn-sm btn-outline-primary rounded-3 shadow-sm py-1 px-2' onClick={() => navigate('/')}>
				Go to Home
			</button>
		</div>
	);
}
