import { useContext, useEffect, useRef, useState } from 'react';
import { AppContext } from '../../app/provider/app_context';
import { generateUniqueId } from '../../utils/funtions';
import { ProductService } from '../../product/service/product_service';
import bootstrap from 'bootstrap/dist/js/bootstrap.min.js';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import FirebaseStorageService from '../../product/service/firebase_storage_service';
import { Timestamp } from 'firebase/firestore';

export function convertToEmbedLink(youtubeLink) {
	const videoIdMatch = youtubeLink.match(
		/(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/
	);

	if (videoIdMatch && videoIdMatch.length > 1) {
		const embedLink = `https://www.youtube.com/embed/${videoIdMatch[1]}`;
		return embedLink;
	} else {
		return null;
	}
}

export default function ProductEditPage() {
	const { showToast } = useContext(AppContext);
	const [loading, setLoading] = useState(false);
	const [editProduct, setEditProduct] = useState(null);
	const [fetchingProduct, setFetchingProduct] = useState(false);
	const { productId } = useParams();
	const navigate = useNavigate();

	const [productImages, setProductImages] = useState([]);
	const [uploadProgress, setUploadProgress] = useState(null);
	const [editedImage, setEditedImage] = useState(false);

	const [productName, setProductName] = useState('');
	const [productPrice, setProductPrice] = useState(0);
	const [productSize, setProductSize] = useState('');
	const [productStock, setProductStock] = useState(0);
	const [productDes, setProductDes] = useState('');
	const [videoLinks, setVideoLinks] = useState([]);

	const fetchProductWithId = async () => {
		if (productId) {
			setFetchingProduct(true);
			const fetchProduct = await ProductService.getProduct(productId);
			console.log('Edit mode product');
			console.log(fetchProduct);
			prepareEditProduct(fetchProduct);
			setFetchingProduct(false);
		}
	};

	useEffect(() => {
		fetchProductWithId();
	}, []);

	const prepareEditProduct = (product) => {
		setEditProduct(product);
		setProductName(product.name);
		setProductPrice(product.price);
		setProductSize(product.size);
		setProductStock(product.stock);
		setProductDes(product.description);
		setVideoLinks(product.videos);
	};

	const clearForm = () => {
		setProductName('');
		setProductPrice('');
		setProductSize('');
		setProductStock(0);
		setProductDes('');
		setVideoLinks([]);
	};

	const updateProduct = async () => {
		try {
			setLoading(true);
			let newProductImageObjects = [];
			if (editedImage) {
				for (const oldImage of editProduct.images ? editProduct.images : []) {
					await FirebaseStorageService.delete(oldImage.refPath);
				}

				for (const newImage of productImages) {
					const imageId = generateUniqueId(10);

					const downloadUrl = await FirebaseStorageService.upload(
						`products/${productId}/${imageId}`,
						newImage,
						newImage.type,
						(progress) => setUploadProgress(progress)
					);
					newProductImageObjects.push({
						downloadUrl: downloadUrl,
						refPath: `products/${productId}/${imageId}`,
					});
				}

				setUploadProgress(null);
			} else {
				newProductImageObjects = editProduct.images ? [...editProduct.images] : [];
			}

			const productModel = {
				product_id: productId,
				images: newProductImageObjects,
				name: productName,
				price: Number(productPrice),
				size: productSize,
				stock: Number(productStock),
				description: productDes,
				videos: videoLinks,
				created_date: Timestamp.now(),
			};
			await ProductService.updateProduct(productModel);
			showToast('Updated Product');
			console.log(productModel);
			clearForm();
			setLoading(false);
			navigate(-1);
		} catch (e) {
			if (e.message) {
				showToast(e.message);
			}
			console.log('Product Error is ' + e);
		}
	};

	const submit = async (e) => {
		e.preventDefault();

		if (editProduct) {
			await updateProduct();
			return;
		}

		if (productImages.length === 0) {
			showToast('Product Image is required');
			return;
		}

		if (!productName) {
			showToast('Product name is required');
			return;
		}
		if (!productPrice) {
			showToast('Product Price is required');
			return;
		}
		if (!productSize) {
			showToast('Product Size is required');
			return;
		}
		if (!productStock) {
			showToast('Product Stock is required');
			return;
		}
		if (!productDes) {
			showToast('Product Des is required');
			return;
		}

		try {
			setLoading(true);
			const newProductId = generateUniqueId(10);
			const imageObjects = [];

			for (const newImage of productImages) {
				const imageId = generateUniqueId(10);

				const downloadUrl = await FirebaseStorageService.upload(
					`products/${newProductId}/${imageId}`,
					newImage,
					newImage.type,
					(progress) => setUploadProgress(progress)
				);
				imageObjects.push({
					downloadUrl: downloadUrl,
					refPath: `products/${newProductId}/${imageId}`,
				});
			}

			console.log(imageObjects);

			setUploadProgress(null);

			const productModel = {
				product_id: newProductId,
				images: imageObjects,
				name: productName,
				price: Number(productPrice),
				size: productSize,
				stock: Number(productStock),
				description: productDes,
				videos: videoLinks,
				created_date: Timestamp.now(),
			};
			await ProductService.addProduct(productModel);
			showToast('Created Product');
			console.log(productModel);
			clearForm();
			setLoading(false);
			navigate(-1);
		} catch (e) {
			if (e.message) {
				showToast(e.message);
			}
			console.log('Product Error is ');
			console.log(e);
			console.log('----------------#');
		}
	};
	return (
		<div>
			<div className='min-vh-100 p-3'>
				{fetchingProduct ? (
					<p className=''>Fetching Product ...</p>
				) : (
					<div style={{ maxWidth: 400 }}>
						<p className='h4'>
							{editProduct ? 'Edit Product' : 'Create Product'}
						</p>
						<form onSubmit={submit}>
							<label htmlFor='' className='form-label'>
								Product Image
							</label>

							<br></br>

							{editProduct && (
								<div className='d-flex'>
									{editProduct.images &&
										editProduct.images.length > 0 &&
										editProduct.images.map((image) => (
											<img
												src={image.downloadUrl}
												alt=''
												width={200}
												height={200}
												className='border border-gray rounded rounded-4 mb-2 me-2'
											/>
										))}
								</div>
							)}
							{((editProduct && editedImage) || !editProduct) && (
								<input
									type='file'
									className='form-control mb-2'
									multiple
									accept='image/*, video/*, audio/*'
									onChange={(e) => {
										setProductImages(e.target.files);
										setEditedImage(true);
										console.log(e.target.files);
									}}
								/>
							)}
							{!editProduct ? (
								<span></span>
							) : editedImage ? (
								<p
									className='text-primary'
									type='button'
									onClick={() => {
										setProductImages([]);
										setEditedImage(false);
									}}>
									Cancel Edit
								</p>
							) : (
								<p
									className='text-primary'
									type='button'
									onClick={() => {
										setProductImages([]);
										setEditedImage(true);
									}}>
									Edit Image
								</p>
							)}

							<label className='form-label'>Name</label>
							<input
								type='text'
								className='form-control mb-2'
								value={productName}
								onChange={(e) => setProductName(e.target.value)}
							/>

							<label className='form-label'>Price</label>
							<input
								type='number'
								className='form-control mb-2'
								value={productPrice}
								onChange={(e) => setProductPrice(e.target.value)}
							/>

							<label className='form-label'>Size</label>
							<input
								type='text'
								className='form-control mb-2'
								value={productSize}
								onChange={(e) => setProductSize(e.target.value)}
							/>

							<label className='form-label'>Stock</label>
							<input
								type='number'
								className='form-control mb-2'
								value={productStock}
								onChange={(e) => setProductStock(Number(e.target.value))}
							/>

							<label className='form-label'>Video Links</label>
							<div className='d-flex overflow-x-auto py-2'>
								{videoLinks.map((video) => (
									<div className='d-flex flex-column me-3'>
										<iframe
											className='bg-light rounded rounded-3 mb-2'
											width='170'
											height='100'
											src={convertToEmbedLink(video.link)}
											title='YouTube video player'
											frameborder='0'
											allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
											referrerpolicy='strict-origin-when-cross-origin'
											allowfullscreen></iframe>

										<div className='d-flex justify-content-between'>
											<a
												className='small ms-1 me-2 text-dark'
												href={video.link}
												target='_blank'
												rel='noreferrer'>
												{video.name}
											</a>
											<i
												class='bi bi-x'
												type='button'
												onClick={() =>
													setVideoLinks(
														videoLinks.filter(
															(e) =>
																e.link !==
																video.link
														)
													)
												}></i>
										</div>
									</div>
								))}
							</div>
							<div className='mb-2 mt-3'>
								<AddVideoButton
									onAdded={(video) =>
										setVideoLinks([...videoLinks, video])
									}
								/>
							</div>

							<label className='form-label'>Description</label>
							<textarea
								type='text'
								className='form-control mb-2'
								rows={5}
								cols={50}
								value={productDes}
								onChange={(e) => setProductDes(e.target.value)}
							/>
							{true && (
								<div
									class='progress'
									role='progressbar'
									aria-label='Animated striped example'
									aria-valuenow='75'
									aria-valuemin='0'
									aria-valuemax='100'>
									<div
										class='progress-bar progress-bar-striped progress-bar-animated'
										style={{ width: `${uploadProgress}%` }}></div>
								</div>
							)}

							<div className='mb-3'></div>
							<div className='d-flex'>
								{loading ? (
									<span className='d-flex align-items-center'>
										<span className='spinner-border me-2'></span>
										<span>Loading..</span>
									</span>
								) : (
									<button className='btn btn-primary px-4'>
										{editProduct ? 'Save' : 'Add'}
									</button>
								)}
								{editProduct && (
									<DeleteProductButton
										product={editProduct}
										onDeleted={() => navigate(-1)}
									/>
								)}
							</div>
						</form>
					</div>
				)}
			</div>
		</div>
	);
}

function AddVideoButton({ onAdded }) {
	const { showToast } = useContext(AppContext);
	const [video, setVideo] = useState({
		name: '',
		link: '',
	});

	const dialog = useRef(null);
	const [modal, setModal] = useState(null);

	const submit = () => {
		if (!video.name) {
			showToast('Video Title is required');
			return;
		}
		if (!video.link) {
			showToast('Video Link is required');
			return;
		}
		onAdded(video);
		setVideo({
			name: '',
			link: '',
		});
		closeDialog();
	};

	const openDialog = async () => {
		var modalDialogInstance = new bootstrap.Modal(dialog.current);
		setModal(modalDialogInstance);
		modalDialogInstance.show();
	};
	const closeDialog = () => {
		// var myModal = document.getElementById('videoAddModal');
		// var modalDialogInstance = new bootstrap.Modal(myModal);
		// modalDialogInstance.hide();
		modal.hide();
	};

	return (
		<>
			<button
				type='button'
				className='btn btn-primary btn-sm'
				onClick={() => openDialog()}>
				Add Video
			</button>

			<div class='modal fade' id='videoAddModal' ref={dialog}>
				<div class='modal-dialog'>
					<div class='modal-content'>
						<div class='modal-header'>
							<h1 class='modal-title fs-5' id='exampleModalLabel'>
								Add Youtube Video
							</h1>
							<button
								type='button'
								class='btn-close'
								data-bs-dismiss='modal'
								aria-label='Close'></button>
						</div>
						<div class='modal-body'>
							<label className='form-label'>Video Title</label>
							<input
								type='text'
								value={video.name}
								className='form-control mb-2'
								onChange={(e) =>
									setVideo({ ...video, name: e.target.value })
								}
							/>
							<label className='form-label'>Video Link</label>
							<input
								type='url'
								value={video.link}
								className='form-control mb-2'
								onChange={(e) =>
									setVideo({ ...video, link: e.target.value })
								}
							/>
						</div>
						<div class='modal-footer'>
							<button
								type='button'
								class='btn btn-secondary'
								data-bs-dismiss='modal'>
								Close
							</button>
							<button type='button' class='btn btn-primary' onClick={submit}>
								Save changes
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

function DeleteProductButton({ product, onDeleted }) {
	const { showToast } = useContext(AppContext);
	const [deleting, setDeleting] = useState(false);

	const dialog = useRef(null);
	const [modal, setModal] = useState(null);

	const submit = async () => {
		try {
			setDeleting(true);
			for (const oldImage of product.images ? product.images : []) {
				await FirebaseStorageService.delete(oldImage.refPath);
			}
			await ProductService.deleteProduct(product.product_id);
			setDeleting(false);
			closeDialog();
			onDeleted();
			showToast('Deleted Product');
		} catch (e) {
			console.log('Product Delete Error ' + e);
			if (e.message) {
				showToast(e.message);
			}
		}
	};

	const openDialog = async () => {
		var modalDialogInstance = new bootstrap.Modal(dialog.current);
		setModal(modalDialogInstance);
		modalDialogInstance.show();
	};
	const closeDialog = () => {
		modal.hide();
	};

	return (
		<>
			<button
				type='button'
				className='btn btn-outline-danger ms-3'
				onClick={() => openDialog()}>
				Delete
			</button>

			<div class='modal fade' id='videoAddModal' ref={dialog}>
				<div class='modal-dialog'>
					<div class='modal-content'>
						<div class='modal-header'>
							<h1 class='modal-title fs-5' id='exampleModalLabel'>
								Delete Product
							</h1>
							<button
								type='button'
								class='btn-close'
								data-bs-dismiss='modal'
								aria-label='Close'></button>
						</div>
						<div class='modal-body'>
							<p>Are you sure to delete this product ?</p>
						</div>
						<div class='modal-footer'>
							<button
								type='button'
								class='btn btn-secondary'
								data-bs-dismiss='modal'>
								Close
							</button>
							{deleting ? (
								<span className='spinner-border'></span>
							) : (
								<button
									type='button'
									class='btn btn-danger'
									onClick={submit}>
									Delete
								</button>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
