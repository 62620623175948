import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import FirebaseAuthService from '../../auth/service/auth_service';
import { SoftwareProductService } from '../../software_product/service/software_product_service';
import { generateUniqueId } from '../../utils/funtions';
import appLogo from '../../assets/images/app_logo.png';

export default function ServiceManagement() {
	const navigate = useNavigate();

	const [softwareProducts, setSoftwareProducts] = useState([]);

	useEffect(() => {
		fetchServices();
	}, []);

	const fetchServices = async () => {
		const data = await SoftwareProductService.get5SoftwareProducts();
		// const clone = [...data, ...data, ...data, ...data, ...data, ...data, ...data, ...data];
		setSoftwareProducts(data);
		window.scroll(0, 0);
	};

	return (
		<div className='min-vh-100 p-lg-4 p-3'>
			<p className='h4'>Service Management</p>
			<button
				className='btn btn-primary btn-sm mb-3'
				onClick={() => navigate('/dashboard/service-management/add')}>
				Add Service
			</button>
			<table className='table table-striped table-hover table-responsive border'>
				<thead>
					<tr>
						<th className='ps-3'>No</th>
						<th>Image</th>
						<th>Name</th>
						<th>Option</th>
					</tr>
				</thead>
				<tbody>
					{softwareProducts.map((softwareProduct, index) => (
						<tr>
							<td className='ps-3'>{index + 1}</td>
							<td>
								

								<img
									src={
										softwareProduct.app_logo &&
										softwareProduct.app_logo
											? softwareProduct.app_logo.downloadUrl
											: appLogo
									}
									alt=''
									width={30}
									height={30}
									className='rounded-circle border border-gray'
								/>
							</td>
							<td>{softwareProduct.name}</td>
							<td>
								<Link
									className='my-0 me-3 '
									to={`/dashboard/service-management/edit/${softwareProduct.product_id}`}>
									Edit
								</Link>
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
}
