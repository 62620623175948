import { useContext, useEffect, useState } from 'react';
import { Timestamp } from 'firebase/firestore';
import { firestore } from '../../firebase';
import { AppContext } from '../provider/app_context';
import NavBar, { NavBarPlacer } from './components/NavBar';
import Footer from './components/Footer';
import './css/TaskManagementPage.css';

export default function TaskManagementPage() {
	const [taskName, setTaskName] = useState('');
	const [completed, setCompleted] = useState(false);
	const [formLoading, setFormLoading] = useState(false);
	const [gettingTasks, setGettingTasks] = useState(false);
	const [editTask, setEditTask] = useState(null);
	const [toDeleteTask, setDeleteTask] = useState(null);
	const { showToast } = useContext(AppContext);

	useEffect(() => {
		getTasks();
	}, []);

	const [tasks, setTasks] = useState([]);

	const clearForm = () => {
		setTaskName('');
		setCompleted(false);
	};

	const getTasks = async () => {
		setGettingTasks(true);
		const snap = await firestore.collection('todo').get();
		const documents = snap.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
		setTasks(documents);
		setGettingTasks(false);
	};

	const prepareEdiTask = (task) => {
		setEditTask(task);
		setTaskName(task.task_name);
		setCompleted(task.completed);
	};

	const updateTask = async (task) => {
		try {
			setFormLoading(true);
			await firestore.collection('todo').doc(task.id).update(task);
			console.log('Updated New Task - ' + JSON.stringify(task));
			clearForm();
			showToast('Task Updated');
			setFormLoading(false);
			getTasks();
			setEditTask(null);
		} catch (e) {
			console.log(`Updating Error - ${e}`);
		}
	};

	const deleteTask = async (task) => {
		try {
			await firestore.collection('todo').doc(task.id).delete();
			console.log('Deleted Task - ' + JSON.stringify(task));
			clearForm();
			showToast('Task Deleted');
			setFormLoading(false);
			getTasks();
			setDeleteTask(null);
		} catch (e) {
			console.log(`Deleted Error - ${e}`);
		}
	};

	const submit = async (e) => {
		e.preventDefault();

		console.log(editTask);

		if (editTask) {
			const updatedTask = {
				...editTask,
				task_name: taskName,
				completed: completed,
				created: Timestamp.now(),
			};
			console.log('Yeah');
			await updateTask(updatedTask);
			return;
		}

		if (taskName === '') {
			showToast('Task Name is required');
			return;
		}
		const newTask = {
			task_name: taskName,
			completed: completed,
			created: Timestamp.now(),
		};

		try {
			setFormLoading(true);
			await firestore.collection('todo').add(newTask);
			console.log('Created New Task - ' + JSON.stringify(newTask));
			clearForm();
			showToast('New Task Added');
			setFormLoading(false);
			getTasks();
		} catch (e) {
			console.log(`Creation Error - ${e}`);
		}
	};
	return (
		<div>
			<NavBar hideOnScroll={false} />
			<NavBarPlacer />
			<div className='row min-vh-100 m-0 bg-light'>
				<div className='col-sm-4'>
					<div className='m-3 p-3 border border-gray shadow-sm task-add-box rounded rounded-4 bg-white'>
						<p className='h4'>{editTask ? 'Modify Task' : 'Add Task	'}</p>
						<form className='d-flex flex-column' onSubmit={submit}>
							<label>Task Name</label>
							<input
								type='text'
								className='form-control mb-2 '
								onChange={(e) => setTaskName(e.target.value)}
								value={taskName}
							/>
							<div className='form-check mb-2'>
								<input
									type='checkbox'
									className='form-check-input me-2 '
									id='completed'
									onChange={(e) => setCompleted(e.target.checked)}
									checked={completed}
								/>
								<label htmlFor='completed' className='form-check-label'>
									Completed
								</label>
							</div>
							{formLoading ? (
								<span className='d-flex align-items-center'>
									<span className='spinner-border me-2'></span>
									<span>Loading..</span>
								</span>
							) : (
								<button className='btn btn-primary'>
									{editTask ? 'Save' : 'Add'}
								</button>
							)}
						</form>
					</div>
				</div>

				<div className='col-sm-7'>
					<div className='m-3 p-3 border border-gray shadow-sm task-add-box rounded rounded-4 bg-white task-add-box d-flex flex-column'>
						<p className='h4 mb-3'>Today Tasks</p>
						{gettingTasks ? (
							<div className='d-flex justify-content-start mt-3'>
								<span className='me-3'>Loading Tasks..</span>
								<span
									className='spinner-border '
									style={{
										width: '1.5rem',
										height: '1.5rem',
										borderWidth: '2px',
									}}></span>
							</div>
						) : (
							<ul className='list-group'>
								{tasks.map((task) => (
									<li
										className='list-group-item d-flex justify-content-between align-items-center bg-light task-list-item'
										onClick={() => prepareEdiTask(task)}>
										<p className='fw-medium fs-6'>{task.task_name}</p>
										<div className='d-flex align-items-center'>
											<span
												className={`rounded rounded-3  px-2 py-0 me-2 `}
												style={{
													border: task.completed
														? '1px solid #1fd655'
														: '1px solid #1d1d1d',
													color: task.completed
														? '#1fd655'
														: '#1d1d1d',
													fontSize: '0.7em',
												}}>
												{task.completed
													? 'Completed'
													: 'Pending'}
											</span>
											<div className='dropdown'>
												<i
													class='bi bi-three-dots-vertical me-2 m-0'
													role='button'
													data-bs-toggle='dropdown'></i>

												<ul className='dropdown-menu'>
													<li>
														<a
															className='dropdown-item'
															onClick={() =>
																prepareEdiTask(task)
															}>
															Modify
														</a>
													</li>
													<li>
														<a
															class='dropdown-item text-danger'
															data-bs-toggle='modal'
															data-bs-target='#taskDeleteModal'
															onClick={() =>
																setDeleteTask(task)
															}
															role='button'>
															Delete
														</a>
													</li>
												</ul>
											</div>
										</div>
									</li>
								))}
							</ul>
						)}
					</div>
				</div>
			</div>

			<div className='m-0'>
				<div
					class='modal fade'
					id='taskDeleteModal'
					tabindex='-1'
					aria-labelledby='exampleModalLabel'
					aria-hidden='true'>
					<div class='modal-dialog'>
						<div class='modal-content'>
							<div class='modal-header'>
								<h1 class='modal-title fs-5' id='exampleModalLabel'>
									Task Delete
								</h1>
								<button
									type='button'
									class='btn-close'
									data-bs-dismiss='modal'
									aria-label='Close'></button>
							</div>
							<div class='modal-body'>Are you sure to delete the task ?</div>
							<div class='modal-footer'>
								<button
									type='button'
									class='btn btn-secondary'
									data-bs-dismiss='modal'>
									Close
								</button>
								<button
									type='button'
									class='btn btn-danger'
									data-bs-dismiss='modal'
									onClick={() => deleteTask(toDeleteTask)}>
									Delete
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}
