import { useParams } from 'react-router-dom';
import Footer from '../../app/view/components/Footer';
import NavBar, { NavBarPlacer } from '../../app/view/components/NavBar';
import { ProductService } from '../service/product_service';
import { useContext, useEffect, useState } from 'react';
import { convertToEmbedLink } from '../../admin_panel/view/ProductEditPage';
import { useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../../auth/provider/auth_context';
import appLogo from '../../assets/images/app_logo.png';
import { useWindowSize } from '../../utils/funtions';
import FacebookShare from '../../app/view/components/FacebookShare';

export default function ProductDetailPage() {
	const [product, setProduct] = useState(null);
	const { productId } = useParams();
	const [fetchingProduct, setFetchingProduct] = useState(false);
	const location = useLocation();

	const navigate = useNavigate();
	const { screenWidth } = useWindowSize();

	const fetchProductWithId = async () => {
		if (productId) {
			setFetchingProduct(true);
			const fetchProduct = await ProductService.getProduct(productId);
			console.log('Fetched Product');
			console.log(fetchProduct);
			setProduct(fetchProduct);
			setFetchingProduct(false);
			window.scroll(0, 0);
		}
	};

	useEffect(() => {
		window.scroll(0, 0);
		fetchProductWithId();
	}, [location]);

	return (
		<div>
			<NavBar />
			<NavBarPlacer />
			<div className='min-vh-100'>
				{fetchingProduct || !product ? (
					<p className='m-3'>Fetching Product...</p>
				) : (
					<div>
						<FacebookShare
							url={window.URL}
							title={product.name}
							description={product.description}
							image={product.images.length > 0 ? product.images[0].downloadUrl : ''}
						/>
						<div className='row mx-0 '>
							<div
								className='col-sm-6 p-3 d-flex justify-content-center align-items-center '
								style={
									screenWidth < 576
										? {}
										: {
												maxHeight: '90vh',
												top: 55,
												position: 'sticky',
										  }
								}>
								{product.images && product.images.length > 0 ? (
									<div
										id='carouselExampleIndicators'
										class='carousel slide '>
										<div class='carousel-inner '>
											{product.images.map((image, index) => (
												<div
													className={`carousel-item  ${
														index === 0 && 'active'
													}`}>
													<img
														src={image.downloadUrl}
														alt='Product'
														width={
															screenWidth < 576
																? 300
																: 400
														}
														height={
															screenWidth < 576
																? 300
																: 400
														}
													/>
												</div>
											))}
										</div>
										<button
											class='carousel-control-prev '
											type='button'
											data-bs-target='#carouselExampleIndicators'
											data-bs-slide='prev'>
											<span
												class='carousel-control-prev-icon bg-dark rounded-circle '
												aria-hidden='true'></span>
											<span class='visually-hidden'>Previous</span>
										</button>
										<button
											class='carousel-control-next'
											type='button'
											data-bs-target='#carouselExampleIndicators'
											data-bs-slide='next'>
											<span
												class='carousel-control-next-icon bg-dark rounded-circle'
												aria-hidden='true'></span>
											<span class='visually-hidden'>Next</span>
										</button>
									</div>
								) : (
									<img
										src={appLogo}
										alt='Product'
										width={300}
										height={300}
										className=''
									/>
								)}
							</div>
							<div className='col-sm-6'>
								<div
									className='m-1 p-lg-3 rounded rounded-4 d-flex flex-column mt-md-5'
									style={{ minWidth: '300px' }}>
									<strong className='fs-4'>{product.name}</strong>
									<p className='my-0'>Size - {product.size}</p>
									<p className='my-0'>Price - {product.price} Ks</p>
									<p className='my-0'>
										Stock -{' '}
										{product.stock <= 0 ? (
											<span className='text-danger'>No Stock</span>
										) : (
											<span className='text-success'>
												Available Now
											</span>
										)}
									</p>
									<strong className='my-0 mt-2'>Description</strong>
									<pre
										className='my-0'
										style={{
											wordWrap: 'wrap',
											whiteSpace: 'pre-wrap',
										}}>
										{product.description}
									</pre>

									{product.videos && product.videos.length > 0 && (
										<strong className='my-0 mt-2'>Videos</strong>
									)}

									<div className='d-flex overflow-x-auto py-2 mb-3'>
										{product.videos.map((video) => (
											<div className='d-flex flex-column me-3'>
												<iframe
													className='bg-light rounded rounded-3 mb-2'
													width='300'
													height='220'
													src={convertToEmbedLink(
														video.link
													)}
													title='YouTube video player'
													frameborder='0'
													allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
													referrerpolicy='strict-origin-when-cross-origin'
													allowfullscreen></iframe>

												<div className='d-flex justify-content-between'>
													<a
														className='small ms-1 me-2 text-gray'
														href={video.link}
														target='_blank'
														rel='noreferrer'>
														{video.name}
													</a>
												</div>
											</div>
										))}
									</div>

									<a
										className='btn btn-primary w-100'
										href='https://www.facebook.com/profile.php?id=100092730696504'
										target='_blank'
										rel='noreferrer'>
										Buy Product
									</a>
								</div>
							</div>
						</div>

						<RelatedProductBoard />
					</div>
				)}
			</div>

			<Footer />
		</div>
	);
}

function RelatedProductBoard() {
	const [products, setProducts] = useState([]);
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		fetchProducts();
	}, []);

	const fetchProducts = async () => {
		setLoading(true);
		const data = await ProductService.get5Products();
		setProducts(data);
		setLoading(false);
	};

	return (
		<div className='d-flex flex-column'>
			<p className='h5 mb-2 mx-3 mt-3'>Related Products</p>
			{loading ? (
				<p className='my-3'>Loading...</p>
			) : (
				<div
					className='row m-0 p-0 justify-content-md-start justify-content-center'
					style={{}}>
					{products.map((product) => (
						<div
							className='col-sm-1 card rounded rounded-4 printer-card p-0'
							style={{ minWidth: 180, maxWidth: 300, margin: '10px 13px' }}
							onClick={() => {
								navigate(`/products/${product.product_id}`);
							}}>
							<img
								src={
									product.images && product.images.length > 0
										? product.images[0].downloadUrl
										: appLogo
								}
								alt={product.name}
								className='card-img-top rounded-top-4'
								width={200}
								height={180}
							/>

							<div className='card-body p-3'>
								<strong className='card-title'>{product.name}</strong>

								<p className='my-0'>Price - {product.price} Ks</p>
								<p className='my-0'>
									{product.stock <= 0 ? (
										<span>No Stock</span>
									) : (
										<small className='text-success'>
											Available Now
										</small>
									)}
								</p>
							</div>
						</div>
					))}
				</div>
			)}
		</div>
	);
}
