import { Timestamp } from 'firebase/firestore';
import { fireAuth, firestore } from '../../firebase';
import { deleteUser } from 'firebase/auth';

class FirebaseAuthService {
	static async getAllUsers() {
		const querySnap = await firestore
			.collection('users')
			.orderBy('created_date', 'desc')
			.get();
		const data = querySnap.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
		return data;
	}

	static async logIn(email, password, onError) {
		try {
			const credential = await fireAuth.signInWithEmailAndPassword(email, password);
			if (credential.user) {
				const docSnap = await firestore
					.collection('users')
					.doc(credential.user.uid)
					.get();

				if (docSnap.exists) {
					return {
						id: docSnap.id,
						...docSnap.data(),
					};
				} else {
					onError({ message: 'Sorry your account was deleted' });
				}

				// else {
				// 	const newUser = {
				// 		id: credential.user.uid,
				// 		name: 'Your Old Account Was Deleted',
				// 		email: email,
				// 		password: password,
				// 		created_date: Timestamp.now(),
				// 		role: 'user',
				// 	};
				// 	await firestore.collection('users').doc(credential.user.uid).set(newUser);
				// 	delete newUser.password;
				// 	return {
				// 		id: credential.user.uid,
				// 		...newUser,
				// 	};
				// }
			}
		} catch (e) {
			console.log(`Log In Error - ${e}`);
			onError(e);
		}
	}
	static async signUp(userModel, onError) {
		try {
			const credential = await fireAuth.createUserWithEmailAndPassword(
				userModel.email,
				userModel.password
			);
			if (credential.user) {
				await firestore.collection('users').doc(credential.user.uid).set(userModel);
				delete userModel.password;
				return {
					id: credential.user.uid,
					...userModel,
				};
			}
		} catch (e) {
			console.log(`Sign Up Error - ${e}`);
			onError(e);
		}
	}

	static async logOut() {
		try {
			await fireAuth.signOut();
			this.forgetUserCredential();
		} catch (e) {
			console.log(`Sign Out Error - ${e}`);
		}
	}

	static async updateUserModel(userModel) {
		try {
			await firestore.collection('users').doc(userModel.id).update(userModel);
		} catch (e) {
			console.log(`User Update Error - ${e}`);
		}
	}

	static async getUserModel(id) {
		try {
			const docSnap = await firestore.collection('users').doc(id).get();
			if (docSnap.exists) {
				return {
					id: docSnap.id,
					...docSnap.data(),
				};
			}
		} catch (e) {
			console.log(`User Update Error - ${e}`);
		}
	}

	static async deleteUserModel(id) {
		await firestore.collection('users').doc(id).delete();
	}

	static async sendResetPasswordEmail(email) {
		try {
			await fireAuth.sendPasswordResetEmail(email);
		} catch (e) {
			console.log(`Send Reset Email ${e}`);
		}
	}
	static async updatePassword(email, currentPassword, newPassword) {
		try {
			const credential = await fireAuth.signInWithEmailAndPassword(email, currentPassword);
			if (credential.user) {
				await credential.user.updatePassword(newPassword);
			}
		} catch (e) {
			console.log(`Update Password Email ${e}`);
		}
	}

	static saveUserCredential(userModel) {
		localStorage.setItem('userCredential', JSON.stringify(userModel));
	}

	static loadUserCredential() {
		const json = localStorage.getItem('userCredential');
		return JSON.parse(json);
	}

	static forgetUserCredential() {
		localStorage.removeItem('userCredential');
	}
}

export default FirebaseAuthService;
