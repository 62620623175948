import './app/view/css/app.css';
import { Outlet, RouterProvider, createBrowserRouter } from 'react-router-dom';
import HomePage from './app/view/HomePage';
import AboutPage from './app/view/AboutPage';
import { AppContext, AppContextProvider } from './app/provider/app_context';
import NotFoundPage from './app/view/NotFoundPage';
import ProductPage from './product/view/ProductPage';
import './firebase';
import TaskManagementPage from './app/view/TaskManagementPage';
import SignInPage from './auth/view/SignInPage';
import SignUpPage from './auth/view/SignUpPage';
import ProfilePage from './auth/view/ProfilePage';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { useContext, useDebugValue, useEffect } from 'react';
import { AuthContextProvider } from './auth/provider/auth_context';
import 'bootstrap/dist/js/bootstrap.min.js';
import ProductEditPage from './admin_panel/view/ProductEditPage';
import ProductDetailPage from './product/view/ProductDetailPage';
import { useLocation, useNavigate } from 'react-router-dom';
import DashboardPage from './admin_panel/view/DashboardPage';
import ProductManagement from './admin_panel/view/ProductManagement';
import UserManagement from './admin_panel/view/UserManagement';
import ServiceManagement from './admin_panel/view/ServiceManagement';
import UserEditPage from './admin_panel/view/UserEditPage';
import ServiceEditPage from './admin_panel/view/ServiceEditPage';
import SoftwareProductPage from './software_product/view/SoftwareProductPage';
import SoftwareProductDetailPage from './software_product/view/SoftwareProductDetailPage';
import FacebookShare from './app/view/components/FacebookShare';
import appLogo from './assets/images/app_logo.png';

function App() {
	const router = createBrowserRouter([
		{
			path: '*',
			element: <NotFoundPage />,
		},
		{
			path: '/',
			element: <MainScreen />,
			errorElement: <NotFoundPage />,
			children: [
				{
					index: true,
					element: <HomePage />,
				},
				{
					path: '/services',
					children: [
						{
							index: true,
							element: <SoftwareProductPage />,
						},
						{
							path: ':productId',
							element: <SoftwareProductDetailPage />,
						},
					],
				},
				{
					path: '/products',
					children: [
						{
							index: true,
							element: <ProductPage />,
						},
						{
							path: ':productId',
							element: <ProductDetailPage />,
						},
					],
				},
				{
					path: '/about',
					element: <AboutPage />,
				},
				{
					path: '/task-management',
					element: <TaskManagementPage />,
				},
				{
					path: '/login',
					element: <SignInPage />,
				},
				{
					path: '/signup',
					element: <SignUpPage />,
				},
				{
					path: '/profile',
					element: <ProfilePage />,
				},
				{
					path: '/dashboard',
					element: <DashboardPage />,
					children: [
						{
							index: true,
							element: <UserManagement />,
						},
						{
							path: '/dashboard/user-management/add',
							element: <UserEditPage />,
						},
						{
							path: '/dashboard/user-management/edit/:userId',
							element: <UserEditPage />,
						},
						{
							path: '/dashboard/product-management',
							element: <ProductManagement />,
						},
						{
							path: '/dashboard/product-management/add',
							element: <ProductEditPage />,
						},
						{
							path: '/dashboard/product-management/edit/:productId',
							element: <ProductEditPage />,
						},
						{
							path: '/dashboard/service-management/add',
							element: <ServiceEditPage />,
						},
						{
							path: '/dashboard/service-management/edit/:productId',
							element: <ServiceEditPage />,
						},
						{
							path: '/dashboard/service-management',
							element: <ServiceManagement />,
						},
					],
				},
			],
		},
	]);

	return (
		<AppContextProvider>
			<AuthContextProvider>
				<RouterProvider router={router} />
				<ToastComponent />
			</AuthContextProvider>
		</AppContextProvider>
	);
}

function MainScreen() {
	return (
		<>
			<FacebookShare
				url={window.URL}
				title="Kanbawza App Studio"
				description="POS Software & Printer Devices"
				image={appLogo}
			/>
			<ScrollToTop />
			<Outlet />
		</>
	);
}

function ToastComponent() {
	const { toast } = useContext(AppContext);
	return (
		<div className={`toast-widget ${toast !== '' && 'show-toast'} d-flex align-items-center`}>
			{toast}
		</div>
	);
}

function ScrollToTop() {
	const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	return null;
}

export default App;
