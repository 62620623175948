import { createContext, useEffect, useState } from 'react';
import { FirebaseAuth } from '../../auth/view/SignUpPage';
import FirebaseAuthService from '../../auth/service/auth_service';

const AuthContext = createContext({});

function AuthContextProvider({ children }) {
	const [currentUser, setCurrentUser] = useState(null);

	

	const syncUserModel = async () => {
		const userModel = await FirebaseAuthService.loadUserCredential();
		if (!userModel) return;
		const syncUserModel = await FirebaseAuthService.getUserModel(userModel.id);

		if (syncUserModel) {
			setCurrentUser(syncUserModel);
			await FirebaseAuthService.saveUserCredential(syncUserModel);
		}
	};

	const authorize = async () => {
		const userModel = await FirebaseAuthService.loadUserCredential();
		setCurrentUser(userModel);
		await syncUserModel();
	};

	useEffect(() => {
		authorize();
	}, []);

	const shippment = {
		currentUser,
		authorize,
		syncUserModel,
	};

	return <AuthContext.Provider value={shippment}>{children}</AuthContext.Provider>;
}

export { AuthContext, AuthContextProvider };
