import { createContext, useEffect, useState } from 'react';

const AppContext = createContext({});

function AppContextProvider({ children }) {
	const [appData, setAppData] = useState({});
	const [toast, setToast] = useState('');

	const updateAppData = (data) => setAppData({ ...appData, ...data });
	const showToast = (message, second = 3000) => {
		setToast(message);
		setTimeout(() => setToast(''), second);
	};

	const shippment = {
		appData,
		updateAppData,
		showToast,
		toast,
	};

	return <AppContext.Provider value={shippment}>{children}</AppContext.Provider>;
}

export { AppContext, AppContextProvider };
