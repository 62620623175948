import { Link, useParams } from 'react-router-dom';
import Footer from '../../app/view/components/Footer';
import NavBar, { NavBarPlacer } from '../../app/view/components/NavBar';
import { useContext, useEffect, useState } from 'react';
import { convertToEmbedLink } from '../../admin_panel/view/ProductEditPage';
import { useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../../auth/provider/auth_context';
import appLogo from '../../assets/images/app_logo.png';
import { useWindowSize } from '../../utils/funtions';
import { SoftwareProductService } from '../service/software_product_service';

export default function SoftwareProductDetailPage() {
	const [softwareProduct, setSoftwareProduct] = useState(null);
	const { productId } = useParams();
	const [fetchingProduct, setFetchingProduct] = useState(false);
	const location = useLocation();

	const navigate = useNavigate();
	const { screenWidth } = useWindowSize();

	const fetchProductWithId = async () => {
		if (productId) {
			setFetchingProduct(true);
			const fetchProduct = await SoftwareProductService.getSoftwareProduct(productId);
			console.log('Fetched Product');
			console.log(fetchProduct);
			setSoftwareProduct(fetchProduct);
			setFetchingProduct(false);
			window.scroll(0, 0);
		}
	};

	useEffect(() => {
		window.scroll(0, 0);
		fetchProductWithId();
	}, [location]);

	return (
		<div>
			<NavBar />
			<NavBarPlacer />
			<div className='min-vh-100'>
				{fetchingProduct || !softwareProduct ? (
					<p className='m-3'>Fetching Product...</p>
				) : (
					<div>
						<div className='row mx-0 '>
							<div
								className='col-sm-6 p-3 d-flex flex-column justify-content-center align-items-center '
								style={
									screenWidth < 576
										? {}
										: {
												maxHeight: '90vh',
												top: 55,
												position: 'sticky',
										  }
								}>
								<img
									src={softwareProduct.app_logo.downloadUrl}
									alt=''
									width={280}
									height={280}
									className='border border-gray rounded rounded-5 mb-2 me-2'
									style={{ boxShadow : '0 0 10px rgba(0,0,0,0.05)' }}
								/>
							</div>
							<div className='col-sm-6'>
								<div
									className='m-1 p-lg-3 rounded rounded-4 d-flex flex-column '
									style={{ minWidth: '300px' }}>
									<strong className='fs-4 mt-3'>
										{softwareProduct.name}
									</strong>
									<p className='my-0'>
										{softwareProduct.short_description}
									</p>
									<p className='my-0'>Price - {softwareProduct.price}</p>

									{softwareProduct.features &&
										softwareProduct.features !== '' && (
											<strong className='my-0 mt-2 mb-2'>
												Features
											</strong>
										)}
									<ul
										className='my-0 mb-2 list-unstyled'
										style={{
											wordWrap: 'wrap',
											whiteSpace: 'pre-wrap',
										}}>
										{softwareProduct.features
											.split('\n')
											.map((line) =>
												line !== '' ? (
													<li>
														<i class=' bi bi-check-circle me-2 small' style={{ color: 'darkgreen' }}></i>
														{line}
													</li>
												) : (
													<span></span>
												)
											)}
									</ul>

									{softwareProduct.feature_images &&
										softwareProduct.feature_images.length > 0 && (
											<strong className='my-0 me-auto mb-3'>
												Feature Photos
											</strong>
										)}

									{softwareProduct && (
										<div className='d-flex flex-nowrap overflow-x-auto'>
											{softwareProduct.feature_images &&
												softwareProduct.feature_images.length >
													0 &&
												softwareProduct.feature_images.map(
													(image) => (
														<a
															href={image.downloadUrl}
															target='_blank'
															rel='noopener noreferrer'>
															<img
																src={
																	image.downloadUrl
																}
																alt=''
																width={180}
																height={400}
																className='border border-gray rounded rounded-3 mb-2 me-3'
															/>
														</a>
													)
												)}
										</div>
									)}
									{softwareProduct.description &&
										softwareProduct.description !== '' && (
											<strong className='my-0 mt-2'>
												Description
											</strong>
										)}
									<pre
										className='my-0'
										style={{
											wordWrap: 'wrap',
											whiteSpace: 'pre-wrap',
										}}>
										{softwareProduct.description}
									</pre>

									{softwareProduct.videos &&
										softwareProduct.videos.length > 0 && (
											<strong className='my-0 mt-2'>Videos</strong>
										)}

									<div className='d-flex overflow-x-auto py-2 mb-3'>
										{softwareProduct.videos.map((video) => (
											<div className='d-flex flex-column me-3'>
												<iframe
													className='bg-light rounded rounded-3 mb-2'
													width='300'
													height='220'
													src={convertToEmbedLink(
														video.link
													)}
													title='YouTube video player'
													frameborder='0'
													allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
													referrerpolicy='strict-origin-when-cross-origin'
													allowfullscreen></iframe>

												<div className='d-flex justify-content-between'>
													<a
														className='small ms-1 me-2 text-gray'
														href={video.link}
														target='_blank'
														rel='noreferrer'>
														{video.name}
													</a>
												</div>
											</div>
										))}
									</div>

									<div className='row mb-5'>
										<div className='col-sm-6'>
											<a
												className='btn btn-primary w-100 mb-2'
												href='https://www.facebook.com/profile.php?id=100092730696504'
												target='_blank'
												rel='noreferrer'>
												Buy Product
											</a>
										</div>
										<div className='col-sm-6'>
											<Link
												className='btn btn-outline-primary w-100'
												to='/products'
												rel='noreferrer'>
												See Printer & Scanner
											</Link>
										</div>
									</div>
								</div>
							</div>
						</div>

						{/* <RelatedProductBoard /> */}
					</div>
				)}
			</div>

			<Footer />
		</div>
	);
}
