import Footer from './components/Footer';
import NavBar, { NavBarPlacer } from './components/NavBar';
import appLogo from '../../assets/images/app_logo.png';

export default function AboutPage() {
	return (
		<div>
			<NavBar hideOnScroll={false} />
			<NavBarPlacer />
			<div className='vh-100 p-3'>
				<div className='m-0 p-4 border boder-gray rounded rounded-4'>
					<img
						src={appLogo}
						alt='Kanbawza App Studio'
						width={150}
						height={150}
						className='border border-gray rounded rounded-5 shadow-sm mb-2'
					/>
					<p className='fs-5 my-0 mb-3 fw-bold'>Kanbawza App Studio</p>
					<p>
						POS Application တွင်လူကြီးမင်းတို့
						လွယ်ကူလျင်မြန်စွာ　စာရင်းများထိန်းချုပ်နိုင်ရန်အကောင်းဆုံးကြိုးစားဆောင်ရွက်ထားပါတယ်ခင်ဗျ။
						<br></br>
						<br></br>
						အသုံးပြုမှုအဆင်မပြေမှုများရှိပါက အကြုံပြုပေးနိုင်ပါတယ်ခင်ဗျ။
						လူကြီးမင်းတို့ အဆင်ပြေအောင်လိုအပ်သည်များကို
						ပြန်လည်ပြုပြင်ဆောင်ရွက်ပေးပါမယ်ခင်ဗျ။
						အသုံးပြုသည့်တွက်ကျေးဇူးအထူးတင်ရှိပါတယ်။  <br></br>
						<br></br>
						လူကြီးမင်းတို့လုပ်ငန်းပေါ်မှုတည်ပြီး App အမျိုးမျိုးကို
						ချိုသာသောစျေးနှုန်းများဖြင့် တာဝန်ယူရေးသားပေးပါတယ်ခင်ဗျ။
						<br></br>
						<br></br>
						Lashio City, Shan State
						<br></br>
						<a
							className='my-0 me-3'
							href='tel:+959445106229'
							target='_blank'
							rel='noreferrer'>
							+959445106229
						</a>
						<a
							className='my-0 mb-4'
							href='tel:+959755513130'
							target='_blank'
							rel='noreferrer'>
							+959755513130
						</a>
					</p>
				</div>
			</div>

			<Footer />
		</div>
	);
}
