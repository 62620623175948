import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/storage';

const firebaseConfig = {
	apiKey: 'AIzaSyBfi7gEp2-qa6plM3u7Mqb2RA_w7-6Yz_w',
	authDomain: 'kanbawza-official-website.firebaseapp.com',
	projectId: 'kanbawza-official-website',
	storageBucket: 'kanbawza-official-website.appspot.com',
	messagingSenderId: '165388694255',
	appId: '1:165388694255:web:3a7a3e77895642fc7ba11a',
	measurementId: 'G-B8WDGK206L',
};

firebase.initializeApp(firebaseConfig);
const firestore = firebase.firestore();
const fireAuth = firebase.auth();
const fireStorage = firebase.storage();

export { firestore, fireAuth, fireStorage };
