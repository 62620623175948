import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../app/provider/app_context';
import NavBar, { NavBarPlacer } from '../../app/view/components/NavBar';
import FirebaseAuthService from '../service/auth_service';
import { Timestamp } from 'firebase/firestore';
import { AuthContext } from '../provider/auth_context';
import { useNavigate } from 'react-router-dom';

export default function ProfilePage() {
	const { currentUser } = useContext(AuthContext);
	const [editMode, setEditMode] = useState(false);
	const [updatePasswordMode, setUpdatePasswordMode] = useState(false);
	const [resetPasswordMode, setResetPasswordMode] = useState(false);
	const navigate = useNavigate();

	return (
		<div>
			<NavBar hideOnScroll={false} />
			<NavBarPlacer />
			{currentUser ? (
				<div
					className='d-flex flex-column border border-dark rounded-4 p-3 m-3'
					style={{ minWidth: 300, maxWidth: 400 }}>
					<img
						src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT9FMCHrgp5aRdsZje0SaqLh3A_3o1g7zjUye9WNw_RJQ&s'
						alt={currentUser.name}
						className='rounded-circle border border-gray p-3 mb-2'
						width={150}
						height={150}
					/>

					<p className='h4'>{currentUser.name}</p>
					<p className='my-0'>Email - {currentUser.email}</p>
					<p className='my-0'>
						Created Date -{' '}
						{new Timestamp(
							currentUser.created_date.seconds,
							currentUser.created_date.nanoseconds
						)
							.toDate()
							.toDateString()}
					</p>

					<div className='my-3'></div>

					{!updatePasswordMode && !resetPasswordMode && (
						<p
							className='text-secondary my-0'
							role='button'
							onClick={() => setEditMode(true)}>
							Edit Account
						</p>
					)}

					{editMode && <EditAccountBoard onCancel={() => setEditMode(false)} />}

					{!editMode && !resetPasswordMode && (
						<p
							className='text-secondary my-0'
							role='button'
							onClick={() => setUpdatePasswordMode(true)}>
							Update Password
						</p>
					)}

					{updatePasswordMode && (
						<UpdatePasswordBoard onCancel={() => setUpdatePasswordMode(false)} />
					)}

					{!editMode && !updatePasswordMode && (
						<p
							className='text-secondary my-0'
							role='button'
							onClick={() => setResetPasswordMode(true)}>
							Reset Password
						</p>
					)}

					{resetPasswordMode && (
						<ResetPasswordBoard onCancel={() => setResetPasswordMode(false)} />
					)}

					{!editMode && !updatePasswordMode && !resetPasswordMode && (
						<p
							className='text-secondary my-0'
							role='button'
							onClick={async () => {
								await FirebaseAuthService.logOut();
								navigate('/login');
							}}>
							Log Out
						</p>
					)}
				</div>
			) : (
				<p className='m-3'>Loading User Data ...</p>
			)}
		</div>
	);
}

function EditAccountBoard({ onCancel }) {
	const { showToast } = useContext(AppContext);
	const { currentUser, syncUserModel } = useContext(AuthContext);
	const [editName, setEditName] = useState(currentUser ? currentUser.name : '');
	const [loading, setLoading] = useState(false);

	const editAccount = async (e) => {
		e.preventDefault();
		const updatedUserModel = {
			...currentUser,
			name: editName,
			created_date: Timestamp.now(),
		};

		try {
			setLoading(true);
			await FirebaseAuthService.updateUserModel(updatedUserModel);
			await syncUserModel();
			setLoading(false);
			showToast('Updated');
			onCancel();
		} catch (e) {
			console.log(e);
			if (e.message) {
				showToast(e.message);
			}
		}
	};

	return (
		<form onSubmit={editAccount}>
			<label className='form-label'>Name</label>
			<input
				type='text'
				className='form-control mb-3'
				value={editName}
				onChange={(e) => setEditName(e.target.value)}
			/>

			<div className='d-flex align-items-center mb-3'>
				{loading ? (
					<span className='spinner-border me-3'></span>
				) : (
					<button className='btn btn-primary py-1 me-3'>Save</button>
				)}
				<p className='text-primary my-0' role='button' onClick={onCancel}>
					Cancel
				</p>
			</div>
		</form>
	);
}

function UpdatePasswordBoard({ onCancel }) {
	const [currentpasswordVisible, setCurrentPasswordVisible] = useState(false);
	const [newPasswordVisible, setNewPasswordVisible] = useState(false);
	const { currentUser, showToast } = useContext(AppContext);
	const [loading, setLoading] = useState(false);

	const [currentPassword, setcurrentPassword] = useState('');
	const [newPassword, setNewPassword] = useState('');

	const updatePassword = async (e) => {
		e.preventDefault();
		if (!currentPassword) {
			showToast('Current password is required');
			return;
		}
		if (!newPassword) {
			showToast('New password is required');
			return;
		}

		try {
			setLoading(true);

			await FirebaseAuthService.updatePassword(
				currentUser.email,
				currentPassword,
				newPassword
			);

			setLoading(false);
			showToast('Updated');
			onCancel();
		} catch (e) {
			console.log(e);
			if (e.message) {
				showToast(e.message);
			}
		}
	};

	return (
		<form onSubmit={updatePassword}>
			<div className='position-relative'>
				<input
					type={currentpasswordVisible ? 'text' : 'password'}
					className='form-control mb-3 mt-2'
					placeholder='Current Password'
					value={currentPassword}
					onChange={(e) => setcurrentPassword(e.target.value)}
				/>
				<div
					className='position-absolute'
					style={{
						top: '50%',
						right: '20px',
						transform: 'translateY(-50%)',
					}}>
					<i
						className={`bi ${
							currentpasswordVisible ? 'bi-eye-fill' : 'bi-eye-slash-fill'
						} fs-5`}
						onClick={() =>
							setCurrentPasswordVisible(!currentpasswordVisible)
						}></i>
				</div>
			</div>

			<div className='position-relative'>
				<input
					type={newPasswordVisible ? 'text' : 'password'}
					className='form-control mb-3 mt-2'
					placeholder='New Password'
					value={newPassword}
					onChange={(e) => setNewPassword(e.target.value)}
				/>
				<div
					className='position-absolute'
					style={{
						top: '50%',
						right: '20px',
						transform: 'translateY(-50%)',
					}}>
					<i
						className={`bi ${
							newPasswordVisible ? 'bi-eye-fill' : 'bi-eye-slash-fill'
						} fs-5`}
						onClick={() => setNewPasswordVisible(!newPasswordVisible)}></i>
				</div>
			</div>

			<div className='d-flex align-items-center'>
				{loading ? (
					<span className='spinner-border me-3'></span>
				) : (
					<button
						className='btn btn-primary py-1 me-3'
						data-bs-target='#confirmModal'
						data-bs-toggle='modal'
						type='button'>
						Update
					</button>
				)}

				<p className='text-primary my-0' role='button' onClick={onCancel}>
					Cancel
				</p>
			</div>

			<div class='modal fade' id='confirmModal' tabindex='-1' aria-hidden='true'>
				<div class='modal-dialog'>
					<div class='modal-content'>
						<div class='modal-header'>
							<h1 class='modal-title fs-5' id='exampleModalLabel'>
								Confirm
							</h1>
							<button
								type='button'
								class='btn-close'
								data-bs-dismiss='modal'
								aria-label='Close'></button>
						</div>
						<div class='modal-body'>Are you sure to update your password ?</div>
						<div class='modal-footer'>
							<button
								type='button'
								class='btn btn-secondary'
								data-bs-dismiss='modal'>
								Close
							</button>
							<button
								type='submit'
								class='btn btn-primary'
								data-bs-dismiss='modal'>
								Update
							</button>
						</div>
					</div>
				</div>
			</div>
		</form>
	);
}

function ResetPasswordBoard({ onCancel }) {
	const [email, setEmail] = useState('');
	const { showToast } = useContext(AppContext);
	const [loading, setLoading] = useState(false);
	const [sent, setSent] = useState(false);

	console.log(onCancel);
	const sendEmail = async (e) => {
		e.preventDefault();
		try {
			if (!email) {
				showToast('Email is empty');
				return;
			}
			setLoading(true);
			await FirebaseAuthService.sendResetPasswordEmail(email);
			setLoading(false);
			showToast('Reset Email Sent');
			setSent(true);
		} catch (e) {
			showToast(e.message);
		}
	};

	return (
		<form onSubmit={sendEmail}>
			{/* <label className='form-label'>Email To Send Reset Email</label> */}
			<input
				type='email'
				className='form-control mb-3 mt-2'
				value={email}
				onChange={(e) => setEmail(e.target.value)}
				placeholder='Your Account Email'
			/>

			<div className='d-flex align-items-center mb-3'>
				{loading ? (
					<span className='spinner-border me-3'></span>
				) : (
					<button className='btn btn-primary py-1 me-3'>Send Email</button>
				)}

				{sent && (
					<a
						className='text-primary my-0 me-3'
						role='button'
						href='mailto:'
						target='_blank'
						rel='noreferrer'>
						Open Mail
					</a>
				)}
				<p className='text-primary my-0' role='button' onClick={onCancel}>
					Cancel
				</p>
			</div>
		</form>
	);
}
