import React from 'react';
import Helmet from 'react-helmet';

const FacebookShare = ({ url, title, description, image }) => {
  return (
    <Helmet>
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
    </Helmet>
  );
};

export default FacebookShare;