import { useContext, useDebugValue, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import NavBar, { NavBarPlacer } from './components/NavBar';
import './css/HomePage.css';
import Footer from './components/Footer';
import { AuthContext } from '../../auth/provider/auth_context';
import printer_set from '../../assets/images/printer_set.png';
import bulb from '../../assets/images/bulb.png';
import mobilePrinterOne from '../../assets/images/mobile_printer_1.jpg';
import posScreenshotOne from '../../assets/images/phone_1.png';
import posScreenshotTwo from '../../assets/images/phone_2.png';
import posScreenshotThree from '../../assets/images/phone_3.png';
import { useInViewport, useWindowSize } from '../../utils/funtions';
import { ProductService } from '../../product/service/product_service';
import appLogo from '../../assets/images/app_logo.png';

export default function HomePage() {
	const navigate = useNavigate();
	const { currentUser } = useContext(AuthContext);

	const [products, setProducts] = useState([]);

	useEffect(() => {
		fetchProducts();
	}, []);

	const fetchProducts = async () => {
		window.scroll(0, 0);
		const data = await ProductService.get5Products();
		const sortedData = data.sort((a, b) => Number(a.price) > Number(b.price));
		setProducts(sortedData);
	};

	return (
		<>
			<NavBar hideOnScroll={false} useBlurBackground={true} />

			<LandingOne />
			<LandingTwo products={products} />
			<LandingThree products={products} />
			<Footer />
			{currentUser && currentUser.role === 'admin' && <TaskManagementButton />}
		</>
	);
}

function TaskManagementButton() {
	const navigate = useNavigate();
	return (
		<i
			class='bi bi-pencil-square task-management-btn'
			onClick={() => navigate('/task-management')}></i>
	);
}

function LandingOne() {
	const navigate = useNavigate();
	const { screenWidth } = useWindowSize();
	let isMobile = screenWidth < 576;
	return (
		<section className='landing-one text-white'>
			<div className='row h-100 p-0 m-0'>
				<div className='col-sm-6 d-flex flex-column justify-content-center align-items-center'>
					<div className='position-relative '>
						<img
							src={printer_set}
							alt='Printer Set'
							height={isMobile ? 300 : 400}
							width={isMobile ? 300 : 400}
							className='init-slide-up-animation animation-delay-1'
						/>
						<div
							className='position-absolute '
							style={{
								top: 80,
								left: isMobile ? 0 : -50,
							}}>
							<img
								src={bulb}
								alt='Bulb'
								height={isMobile ? 50 : 100}
								width={isMobile ? 50 : 100}
								className='blub init-slide-up-animation animation-delay-2'
							/>
						</div>
						<div
							className='position-absolute '
							style={{
								top: isMobile ? 50 : 10,
								right: isMobile ? 0 : -50,
							}}>
							<img
								src={bulb}
								alt='Bulb'
								height={isMobile ? 50 : 100}
								width={isMobile ? 50 : 100}
								className='blub init-slide-up-animation animation-delay-2'
								style={{
									'-webkit-filter': 'drop-shadow(0 0 5px goldenrod)',
									filter: 'drop-shadow(0 0 5px goldenrod)',
								}}
							/>
						</div>
					</div>
				</div>
				<div className='col-sm-6 d-flex flex-column justify-content-center  align-items-center'>
					<div
						className='d-flex flex-column align-items-center align-items-sm-start'
						style={{ maxWidth: '500px' }}>
						<p className='h2 init-slide-left-animation animation-delay-3'>
							POS Supplier
						</p>
						<p
							className={`${
								isMobile ? 'text-center' : ''
							} init-slide-left-animation animation-delay-3`}>
							We are selling POS supplier devices including mobile printers,
							bluetooth scanner. With a right price and good quality for longer
							use, you can choose your good ones here.
						</p>
						<button
							className='btn btn-light init-slide-left-animation animation-delay-3'
							onClick={() => navigate('/products')}>
							See Product
						</button>
					</div>
				</div>
			</div>
		</section>
	);
}
function LandingTwo({ products }) {
	const navigate = useNavigate();
	// const samplePrinters = [
	// 	{
	// 		name: '58mm Receipt Printer',
	// 		price: '100000 Ks',
	// 		size: '58mm',
	// 		image: mobilePrinterOne,
	// 		postion: 'translate(0,0)',
	// 		animation: '',
	// 	},
	// 	{
	// 		name: '80mm Label Printer',
	// 		price: '160000 Ks',
	// 		size: '58mm',
	// 		image: mobilePrinterOne,
	// 		animation: 'init-slide-up-animation animation-delay-2',
	// 	},
	// 	{
	// 		name: '80mm Receipt Printer',
	// 		price: '225000 Ks',
	// 		size: '80mm',
	// 		image: mobilePrinterOne,
	// 		animation: 'init-slide-up-animation animation-delay-3',
	// 	},
	// 	{
	// 		name: '80mm Label Printer',
	// 		price: '260000 Ks',
	// 		size: '80mm',
	// 		image: mobilePrinterOne,
	// 		animation: 'init-slide-up-animation animation-delay-4',
	// 	},
	// ];

	const [isVisible, setIsVisible] = useState(false);
	const ref = useRef(null);

	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => {
				setIsVisible(entry.isIntersecting);
			},
			{
				threshold: [0.1],
			}
		);

		if (ref.current) {
			observer.observe(ref.current);
		}

		return () => {
			if (ref.current) {
				observer.unobserve(ref.current);
			}
		};
	}, []);

	return (
		<>
			{products && products.length === 0 ? (
				<span></span>
			) : (
				<section className='landing-two text-white' ref={ref}>
					<div className='d-flex flex-column justify-content-center align-items-center pt-5'>
						<p
							className={`h1 mt-5 ${
								isVisible ? 'init-slide-up-animation ' : 'opacity-1'
							}`}>
							POS Tools and Hardware
						</p>
						<p
							className={`h5 mb-3 ${
								isVisible ? 'init-slide-up-animation ' : 'opacity-1'
							}`}>
							Find Your Good Product
						</p>
						<button
							className={`btn btn-outline-light px-3 py-1 ${
								isVisible ? 'init-slide-up-animation ' : 'opacity-1'
							}`}
							onClick={() => navigate('/products')}>
							See More
						</button>
						<div
							className='d-flex flex-nowrap mt-5'
							style={{
								gap: '30px',
								overflowX: 'auto',
								overflowY: 'hidden',
								maxWidth: '80vw',
							}}>
							{products.map((printer) => (
								<div
									className={`card rounded-4 shadow border-0 ${
										isVisible
											? 'init-slide-up-animation animation-delay-1'
											: 'opacity-1'
									}`}
									style={{ width: '170px', minWidth: '170px' }}>
									<img
										src={
											printer.images && printer.images.length > 0
												? printer.images[0].downloadUrl
												: appLogo
										}
										alt={printer.name}
										className='card-img-top rounded-top-4'
										width={170}
										height={170}
									/>
									<div className='card-body'>
										<strong className='card-title'>
											{printer.name}
										</strong>
										<p className='card-text d-flex flex-column'>
											<small>Price - {printer.price} Ks</small>

											<small>Size - {printer.size}</small>
										</p>
									</div>
								</div>
							))}
						</div>
					</div>
				</section>
			)}
		</>
	);
}
function LandingThree({ products }) {
	const navigate = useNavigate();
	const { screenWidth } = useWindowSize();
	let isMobile = screenWidth < 576;
	const smaplePOSScreenshots = [
		{
			postion: 'translate(0,0)',
			image: posScreenshotTwo,
			animation: 'init-slide-up-animation ',
		},
		{
			postion: isMobile ? 'translate(0,0)' : 'translate(0,-30px)',
			image: posScreenshotOne,
			animation: 'init-slide-up-animation',
		},
		{
			postion: 'translate(0,0)',
			image: posScreenshotThree,
			animation: 'init-slide-up-animation ',
		},
	];

	const [isVisible, setIsVisible] = useState(false);
	const ref = useRef(null);

	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => {
				setIsVisible(entry.isIntersecting);
			},
			{
				threshold: [0],
			}
		);

		if (ref.current) {
			observer.observe(ref.current);
		}

		// Cleanup observer
		return () => {
			if (ref.current) {
				observer.unobserve(ref.current);
			}
		};
	}, []);

	return (
		<section
			className='landing-three text-white'
			ref={ref}
			style={{
				background:
					products && products.length > 0
						? 'linear-gradient(to bottom, #142974, #09003D)'
						: 'linear-gradient(to bottom, #09003D, #142974)',
			}}>
			<div className='d-flex flex-column justify-content-center align-items-center pt-5'>
				<div
					className='d-flex flex-nowrap mt-5 '
					style={{
						gap: '50px',
						overflowX: 'auto',
						overflowY: 'hidden',
						maxWidth: '80vw',
					}}>
					{smaplePOSScreenshots.map((screenshot) => (
						<img
							src={screenshot.image}
							alt='Mobile POS'
							className={`shadow ${
								isVisible ? screenshot.animation : 'opacity-0'
							}`}
							width={180}
							height={400}
							style={{
								transform: screenshot.postion,
							}}
						/>
					))}
				</div>

				<p
					className={`h1 mt-5 ${
						isVisible
							? 'init-slide-left-animation animation-delay-2'
							: 'opacity-0'
					}`}>
					Software Packages
				</p>
				<p
					className={`h5 mb-3 text-center ${
						isVisible
							? 'init-slide-left-animation animation-delay-2'
							: 'opacity-0'
					}`}>
					POS Application and Inventory Management System{' '}
				</p>
				<button
					className={`btn btn-outline-light px-3 py-1 mb-5 ${
						isVisible
							? 'init-slide-left-animation animation-delay-3'
							: 'opacity-0'
					}`}
					onClick={() => navigate('/services')}>
					See More
				</button>
			</div>
		</section>
	);
}
